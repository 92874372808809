<template>
  <b-tab
    title="Problema"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab && !store.isUpdate"
    class="mt-5"
    @click="() => (store.currentTab = 2)"
  >
    <b-row class="p-4">
      <b-col class="w-100">
        <b-form-group label="Problema" label-for="problema">
          <v-textarea full-width rows="6" v-model="form.problema" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row align-v="center" v-if="store.trabalharHorasOs">
      <b-col md="12">
        <b-form-group label="Período do serviço?" label-for="periodo">
          <b-form-select v-model="periodo">
            <b-form-select-option
              v-for="(p, index) in periodos"
              :value="p.descricao"
              :key="'tipo_periodo' + index"
            >
              {{ p.descricao }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group id="dataServico" label-for="dataServico" label="Data">
          <b-form-input type="date" v-model="dataServico"> </b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          id="horaInicio"
          label-for="horaInicio"
          label="Hora de Início"
        >
          <b-form-input type="time" v-model="horaInicio"> </b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group id="horaFinal" label-for="horaFinal" label="Hora Final">
          <b-form-input type="time" v-model="horaFinal"> </b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          id="valorServico"
          label-for="valorServico"
          label="R$ Valor do horário"
        >
          <money
            class="form-control"
            id="unitario"
            name="unitario"
            v-model="valorServico"
            v-bind="priceOptions"
          >
          </money>
        </b-form-group>
      </b-col>
      <b-col md="1">
        <button
          v-on:click="addServico()"
          v-b-tooltip.bottom="'Adicionar Horário'"
        >
          <Plus class="btn btn-sm btn-info btn-hover-info btn-icon mr-2" />
        </button>
      </b-col>
      <b-col md="12">
        <b-table-responsive>
          <table class="table w-100" id="table-horarios">
            <thead>
              <tr>
                <th scope="col">Data</th>
                <th scope="col">Período</th>
                <th scope="col">Hora Início</th>
                <th scope="col">Hora Final</th>
                <th scope="col">R$ Valor</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in horarios" :key="index">
              <tr v-show="index != 'totalHoras'">
                <th>{{ formatDate(item.dataServico) }}</th>
                <th>{{ item.periodo }}</th>
                <td>{{ item.horaInicio }}</td>
                <td>{{ item.horaFinal }}</td>
                <td>{{ numberToReal(item.valorServico, 2) }}</td>
                <td>
                  <button
                    @click="excluir(index)"
                    class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                    title="Excluir"
                  >
                    <i class="la la-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td><strong>Total de Horas:</strong></td>
                <td>
                  <strong>{{ totalHoras }}</strong>
                </td>
              </tr>
              <tr>
                <td><strong>Total R$:</strong></td>
                <td>
                  <money
                    class="form-control"
                    id="total"
                    name="total"
                    v-model="totalReaisHorarioEditavel"
                    v-bind="priceOptions"
                  >
                  </money>
                </td>
              </tr>
            </tfoot>
          </table>
        </b-table-responsive>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { store } from "../../storeOS";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import Plus from "vue-material-design-icons/PlusThick.vue";
import { MONEY as settingMoney } from "@/modules/Application";
import { priceOptions } from "@/modules/Application";
import { Money } from "v-money";

export default {
  name: "app-os-form-problemaTab",
  components: {
    Plus,
    Money,
  },
  data() {
    return {
      priceOptions,
      configMoney: settingMoney,
      totalHoras: "00:00",
      totalReaisHorario: "0,00",
      store,
      loading: false,
      windowWidth: window.innerWidth,
      form: {
        problema: null,
      },
      horarios: [],
      periodos: [
        {
          id: 1,
          descricao: "Manhã",
        },
        {
          id: 2,
          descricao: "Tarde",
        },
      ],
      horaInicio: null,
      horaFinal: null,
      valorServico: 0,
      periodo: null,
      dataServico: null,
      totalReaisHorarioEditavel: this.numberToReal(this.totalReaisHorario, 2),
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      await this.$nextTick(); // Aguarda a próxima atualização do DOM
      console.log(this.store.formGerais);

      if (this.store.formGerais && this.store.formGerais.dataEntrada) {
        this.dataServico = this.store.formGerais.dataEntrada;
      }

      if (Object.keys(this.$route.params).length !== 0) {
        const { problema, horarios } = this.$route.params.osData;
        this.form.problema = problema;
        this.form.horarios = horarios;

        if (horarios) {
          this.horarios = horarios;

          const data = await this.$http.post(
            "ordemservico/calcula-horas",
            horarios
          );
          this.totalHoras = data.data.totalHoras;
          this.totalReaisHorarioEditavel = data.data.valorTotal;
        }
      }
    },
    async addServico() {
      if (!this.horaInicio) {
        this.$toast.warning("Informe a hora inicial!");
        return;
      }

      if (!this.horaFinal) {
        this.$toast.warning("Informe a hora final!");
        return;
      }

      if (!this.dataServico) {
        this.$toast.warning("Informe a data do serviço!");
        return;
      }

      if (!this.periodo) {
        this.$toast.warning("Informe o período do serviço!");
        return;
      }

      var servico = {};

      servico.dataServico = this.dataServico;
      servico.horaInicio = this.horaInicio;
      servico.horaFinal = this.horaFinal;
      servico.periodo = this.periodo;
      servico.valorServico = this.valorServico;
      this.horarios.push(servico);
      const data = await this.$http.post(
        "ordemservico/calcula-horas",
        this.horarios
      );
      this.totalHoras = data.data.totalHoras;
      this.totalReaisHorarioEditavel = data.data.valorTotal;
      this.form.horarios = this.horarios;
      this.limpaServico();
      this.$toast.success("Horário adicionado com sucesso!");
    },
    limpaServico() {
      this.horaInicio = null;
      this.horaFinal = null;
      this.periodo = null;
      this.valorServico = 0.0;
      this.dataServico = null;
    },
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir horário prestado?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.horarios.splice(data, 1);
            const total = await this.$http.post(
              "ordemservico/calcula-horas",
              this.horarios
            );
            this.totalHoras = total.data.totalHoras;
            this.totalReaisHorarioEditavel = total.data.valorTotal;
            this.$toast.success("Horário excluído!");
          }
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  async created() {
    await this.getDataInit();
  },
  watch: {
    form: {
      handler(value) {
        this.store.formProblema = value;
      },
      deep: true,
    },
    "store.formGerais": {
      handler(newVal) {
        if (newVal && newVal.dataEntrada) {
          this.dataServico = newVal.dataEntrada;
        }
      },
      deep: true,
      immediate: true,
    },
    totalReaisHorarioEditavel(newVal) {
      if (this.horarios.length > 0) {
        // Garantir que newVal seja tratado como string para substituir caracteres
        let total =
          typeof newVal === "string"
            ? parseFloat(newVal.replace(/[^\d,]/g, "").replace(",", "."))
            : parseFloat(newVal);

        if (!isNaN(total) && total > 0) {
          // Calcular novo valor para cada serviço
          let valorDividido = total / this.horarios.length;

          // Atualizar o valorServico de cada item na lista
          this.horarios = this.horarios.map((item) => ({
            ...item,
            valorServico: valorDividido.toFixed(2), // Mantém 2 casas decimais
          }));
          this.form.horarios = this.horarios;
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 2;
    },
  },
};
</script>
<style>
.table-horarios {
  width: 100% !important;
  table-layout: fixed;
}
</style>
