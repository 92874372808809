<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Nome',
          field: 'nome',
        },
      ]"
    />

    <TableApp
      titulo="Variações"
      acaoBtn="/#/variacoes/novo"
      textoBtn="Nova"
      api-url="/variavel/lista"
      :fields="fields"
      classFilterColumn="produto"
      ref="produto"
      :perPage="10"
    >
      <template slot="customRow" scope="props">
        <span slot="nome" v-if="props.field == 'nome'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.nome }}</a
                >
              </div>
            </div>
          </span>
        </span>
 
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <router-link
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Editar'"
                  :to="{
                    name: 'variacoesUpdate',
                    params: {
                      id_variavel: props.rowData.id_variavel,
                    },
                  }"
                >
                  <Pencil :size="16" title="Editar" />
                </router-link>
                <button
                  @click="excluir(props.rowData)"
                  class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Excluir'"
                >
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import { onboardStore } from "../../../onboardStore";
import FilterFields from "@/components/FilterFields";
import { actionsStore } from "@/components/Table/actionsStore";
import swal from "sweetalert";
import { MONEY as settingMoney } from "@/modules/Application";

export default {
  name: "app-produto",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
    FilterFields,
  },
  data() {
    return {
      actionsStore,
      onboardStore,
      newProductDescription: null,
      fields: [
        {
          label: "Nome",
          field: "nome",
          type: "text",
          slot: true,
          sortable: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
      percentual: 0,
      configMoney: settingMoney,
      estoque_total: false,
    };
  },
  methods: {
    avatarRamdom() {
      var valor = Math.floor(Math.random() * 10) - 1;

      return valor + ".svg";
    },
    async duplicarProduto() {
      const id_produto = this.actionsStore?.selectedRows[0]?.id;
      this.addLoadingToButton("doubleProduct");
      if (!id_produto) {
        this.$toast.error("Produto sem id válido!");
        this.removeLoadingToButton("doubleProduct");
        return;
      }
      if (!this.newProductDescription) {
        this.$toast.error("Nova descrição obrigatória!");
        this.removeLoadingToButton("doubleProduct");
        return;
      }
      try {
        await this.$http.post("/produtos/duplicar", {
          id_produto,
          descricao: this.newProductDescription,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Produto duplicado com sucesso!");
        this.$refs.produto.refresh();
        this.newProductDescription = null;
        this.$bvModal.hide("duplicarProduto");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("doubleProduct");
    },
    async excluirProduto() {
      this.addLoadingToButton("excluirProdutos");
      try {
        await this.$http
          .post("/produtos/delete-all", {
            produtos: this.actionsStore.selectedRows.map((produto) => ({
              id: produto.id,
            })),
          })
          .then((result) => {
            this.actionsStore.selectedRows = [];
            this.actionsStore.updateTable = !this.actionsStore.updateTable;
            this.$toast.success("Solicitação realizada com sucesso!");
            this.$refs.produto.refresh();
            this.$bvModal.hide("menuAcoes");
            this.$bvModal.hide("excluirProdutos");
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("excluirProdutos");
      } catch (error) {
        this.$bvModal.hide("excluirProdutos");
      }
      this.removeLoadingToButton("excluirProdutos");
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir a variação " + data.nome + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/variavel/delete", {
                id_variavel: data.id_variavel,
              });
              this.$refs.produto.refresh();
              this.$toast.success("Variação excluída!");
            }
          });
      });
    },
    setEstoqueTotal() {
      this.estoque_total = this.estoque_total == true ? false : true;
    },
    async ajustaPreco() {
      this.addLoadingToButton("reajustePreco");
      if (!this.percentual) {
        this.$toast.error("Informe o percentual!");
        this.removeLoadingToButton("reajustePreco");
        return;
      }
      try {
        await this.$http.post("/produtos/ajuste-preco", {
          produtos: this.actionsStore.selectedRows.map((produto) => ({
            id_produto: produto.id,
          })),
          estoque_total: this.estoque_total,
          percentual: this.percentual,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Ajuste aplicado com sucesso!");
        this.$refs.produto.refresh();
        this.percentual = 0;
        this.estoque_total = false;
        this.actionsStore.selectedRows = [];
        this.$bvModal.hide("reajustePreco");
      } catch (error) {
        this.percentual = 0;
        this.estoque_total = false;
        console.log(error);
      }
      this.removeLoadingToButton("reajustePreco");
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
