import { required } from "vuelidate/lib/validators";

export default class EmitenteData {
  nome = "";

  cnpj_cpf = "";

  ie_rg = "";

  responsavel = "";

  nome_fantasia = "";

  inscricao_municipal = "";

  site = "";

  cep = "";

  logradouro = "";

  numero = "";

  complemento = "";

  bairro = "";

  fiscal = "";

  crt = "";

  cnae = "";

  optante_simples = "";

  aliquota_simples = "";

  nfce_versao = "";

  nome_certificado = "";

  senha_certificado = null;

  logo = "";

  id_cidade = "";

  id_empresa = null;

  fone = "";

  email = "";

  validade_certificado = "";

  constructor({
    nome = "",
    cnpj_cpf = "",
    ie_rg = "",
    responsavel = "",
    nome_fantasia = "",
    inscricao_municipal = "",
    site = "",
    cep = "",
    logradouro = "",
    numero = "",
    complemento = "",
    bairro = "",
    fiscal = false,
    crt = "",
    cnae = "",
    optante_simples = "",
    aliquota_simples = "",
    nome_certificado = "",
    senha_certificado = null,
    logo = "",
    id_cidade = "",
    id_empresa = null,
    fone = "",
    email = "",
    validade_certificado = "",
  }) {
    this.nome = nome;
    this.cnpj_cpf = cnpj_cpf;
    this.ie_rg = ie_rg;
    this.responsavel = responsavel;
    this.nome_fantasia = nome_fantasia;
    this.inscricao_municipal = inscricao_municipal;
    this.site = site;
    this.cep = cep;
    this.logradouro = logradouro;
    this.numero = numero;
    this.complemento = complemento;
    this.bairro = bairro;
    this.fiscal = fiscal;
    this.crt = crt;
    this.cnae = cnae;
    this.optante_simples = optante_simples;
    this.aliquota_simples = aliquota_simples;
    this.nome_certificado = nome_certificado;
    this.senha_certificado = senha_certificado;
    this.logo = logo;
    this.id_cidade = id_cidade;
    this.id_empresa = id_empresa;
    this.fone = fone;
    this.email = email;
    this.validade_certificado = validade_certificado;
  }
}

export const EmitenteDataRequired = {
  nome: {
    required,
  },
  nome_fantasia: {
    required,
  },
  cnpj_cpf: {
    required,
  },
  cep: {
    required,
  },
  logradouro: {
    required,
  },
  responsavel: {
    required,
  },
  numero: {
    required,
  },
  bairro: {
    required,
  },
  ie_rg: {
    required,
  },
  fone: {
    required,
  },
  email: {
    required,
  },
};
