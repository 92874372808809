<template>
  <b-tab
    title="Produtos"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab"
    class="mt-5"
  >
    <b-row align-v="center">
      <b-col sm="10">
        <b-form-group label="Nome" label-for="name">
          <VueSelect
            ref="buscarProduto"
            :filterable="false"
            @input="setProduto"
            :options="produtosFetched"
            :class="{ hasErrors: produtoError, inputFilled: produtoFilled }"
            @search="onSearchProduto"
            v-model="descricao"
          >
            <template slot="no-options"> Informe o produto pelo nome </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>{{ option.codigo }} - {{ option.descricao }}</div>
                <span>{{
                  !option.isKit
                    ? `R$: ${numberToReal(option.preco)} - ` +
                      `QTD: ${option.quantidade}`
                    : `R$
                  ${numberToReal(option.total)}`
                }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.label }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col class="mt-1">
        <b-dropdown
          id="dropdown-dropright"
          dropright
          variant="primary"
          v-b-tooltip.hover.right
          title="Mudar tipo de filtro"
        >
          <template #button-content>
            <FilterOutline :size="20" class="mt-" />
          </template>
          <b-dropdown-item
            @click="
              () => {
                typeFilter = 1;
              }
            "
            :class="typeFilter === 1 ? 'bg-primary' : ''"
          >
            <p :class="typeFilter === 1 ? 'text-white' : ''">Começando com</p>
          </b-dropdown-item>
          <b-dropdown-item
            @click="
              () => {
                typeFilter = 2;
              }
            "
            :class="typeFilter === 2 ? 'bg-primary' : ''"
          >
            <p :class="typeFilter === 2 ? 'text-white' : ''">Terminando com</p>
          </b-dropdown-item>
          <b-dropdown-item
            @click="
              () => {
                typeFilter = 3;
              }
            "
            :class="typeFilter === 3 ? 'bg-primary' : ''"
          >
            <p :class="typeFilter === 3 ? 'text-white' : ''">Contendo</p>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row>
      <b-col :cols="windowWidth > 768 ? 0 : 6">
        <b-form-group label="Quantidade" label-for="quantidade">
          <money
            class="form-control"
            id="quantidade"
            name="quantidade"
            v-model="quantidade"
            v-bind="quantityOptions"
          >
          </money>
        </b-form-group>
      </b-col>
      <b-col :cols="windowWidth > 768 ? 0 : 6">
        <b-form-group label="Unitário" label-for="preco">
          <money
            class="form-control"
            id="preco"
            name="preco"
            v-model="preco"
            v-bind="priceOptions"
          >
          </money>
        </b-form-group>
      </b-col>
      <b-col :cols="windowWidth > 768 ? 0 : 6">
        <b-form-group label="Desconto" label-for="desconto">
          <money
            class="form-control"
            id="desconto"
            name="desconto"
            v-model="desconto"
            @input="setDesconto"
            v-bind="configMask"
          >
          </money>
        </b-form-group>
      </b-col>
      <b-col :cols="windowWidth > 768 ? 0 : 6">
        <b-form-group label="Total R$" label-for="total">
          <money
            disabled
            class="form-control"
            id="total"
            name="total"
            v-model="total"
            v-bind="priceOptions"
          >
          </money>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-for="add">
          <button @click="addProduto(produtoSelected)">
            <Plus
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2 mt-9"
              v-if="windowWidth > 768"
            />
            <button
              v-else
              class="btn btn-info btn-hover-dark font-weight-bolder font-size-lg ml-2"
            >
              Adicionar produto
            </button>
          </button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <TableApp
          item="produto"
          subTitulo=""
          :data="produtos"
          :fields="fields"
          classFilterColumn="nome"
          ref="nome"
        >
          <template slot="customRow" scope="props">
            <span
              slot="descricao"
              class="pl-0 pt-8"
              v-if="props.field == 'descricao'"
            >
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ props.rowData.descricao }}
                </div>
              </div>
            </span>
            <span
              slot="quantidade"
              class="pl-0 pt-8"
              v-if="props.field == 'quantidade'"
            >
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ numberToReal(props.rowData.quantity, "quantidade") }}
                </div>
              </div>
            </span>
            <span slot="preco" class="pl-0 pt-8" v-if="props.field == 'preco'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ numberToReal(props.rowData.price, "preco") }}
                </div>
              </div>
            </span>
            <span
              slot="desconto"
              v-if="props.field == 'desconto'"
              class="text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              R$
              {{ numberToReal(props.rowData.discount, 2) }}
            </span>
            <span
              slot="total"
              v-if="props.field == 'total'"
              class="text-dark-75 font-weight-bolder d-block font-size-lg"
            >
              R$
              {{
                numberToReal(
                  props.rowData.quantity * props.rowData.price -
                    (props.rowData?.discount ?? 0),
                  "preco"
                )
              }}
            </span>
            <span slot="actions" v-if="props.field == 'actions'">
              <div class="row-actions" style="text-align: right !important">
                <span
                  style="overflow: visible; position: relative; width: 130px"
                >
                  <div class="action-buttons">
                    <button
                      @click="
                        openModalProduto(
                          props.rowData,
                          props.rowData.currentIndex
                        )
                      "
                      class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                    >
                      <Pencil :size="16" title="Editar" />
                    </button>
                    <button
                      @click="handleDeleteProduto(props.rowData)"
                      class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                    >
                      <TrashCanIcon :size="16" />
                    </button>
                  </div>
                </span>
              </div>
            </span>
          </template>
        </TableApp>

        <ModalProduto
          :close="closeModalProduto"
          :changeProduto="saveEditProduto"
          :produto="produtoToEdit"
          :open="modalProdutoIsOpen"
          v-if="modalProdutoIsOpen"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <button
          class="btn btn-primary font-weight-bold"
          @click="openNewProduto"
        >
          Adicionar novo Produto
        </button>
      </b-col>
    </b-row>
    <VariacaoProdutoModal
      :addProduto="addProduto"
      :produto="produtoSelected"
      @onAddProduto="
        ({ newPreco, newQuantidade, newTotal }) => {
          preco = formatNumberToFloat(newPreco);
          quantidade = newQuantidade;
          total = newTotal;
        }
      "
    />
    <LoteProdutoModal
      :addProduto="addProduto"
      :produto="produtoSelected"
      :compra="true"
      :atualizarLotes="atualizarLotes"
      @onAddProduto="
        ({ newPreco, newQuantidade, newTotal }) => {
          preco = formatNumberToFloat(newPreco);
          quantidade = newQuantidade;
          total = newTotal;
        }
      "
    />
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import { Money } from "v-money";
import {
  MONEY as settingMoney,
  priceOptions,
  quantityOptions,
} from "@/modules/Application";
import "vue-select/dist/vue-select.css";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import Plus from "vue-material-design-icons/PlusThick.vue";
import TableApp from "../../../../components/Table/Table3.vue";
import ModalProduto from "./ModalProduto.vue";
import { validationMixin } from "vuelidate";
import ProdutoData, { ProdutoDataRequired } from "../ProdutoData";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeCompra";
import FilterOutline from "vue-material-design-icons/Filter";
import VariacaoProdutoModal from "@/components/VariacaoProdutoModal";
import LoteProdutoModal from "@/components/LoteProdutoModal";

export default {
  name: "app-compra-form-ProdutoTab",
  components: {
    VueSelect,
    ModalProduto,
    TableApp,
    Pencil,
    Plus,
    TrashCanIcon,
    Money,
    FilterOutline,
    VariacaoProdutoModal,
    LoteProdutoModal,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      quantityOptions,
      priceOptions,
      configMask: settingMoney,
      windowWidth: window.innerWidth,
      typeFilter: 3,
      fields: [
        {
          label: "Nome",
          field: "descricao",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Qtd",
          field: "quantidade",
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Unitário",
          field: "preco",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Desconto",
          field: "desconto",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "Total",
          field: "total",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "20%",
        },
      ],
      form: {},
      descricao: "",
      quantidade: 1,
      preco: 0,
      desconto: 0,
      total: 0,
      produtoSelected: null,
      estoqueProduto: 0,
      produtosFetched: [],
      produtos: [],
      produtoToEdit: null,
      modalProdutoIsOpen: false,
      dataSendToApi: {},
      produtoError: false,
      produtoFilled: false,
      produtoToImpostos: null,
      atualizarLotes: 0,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  validations: {
    form: ProdutoDataRequired,
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
          JSON.stringify(newValue);
          return;
        }
        this.store.formProduto = newValue;
      },
      deep: true,
    },

    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 4 && oldValue < newValue) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toast.error("Necessario incluir um produto");
          this.produtoError = true;
          this.store.currentTab = oldValue;
          return;
        }
        const invalidProduct = this.form.produtos.find(
          (produto) =>
            produto.cfop === null ||
            produto.cst_csosn === null ||
            produto.ncm === null
        );
        if (invalidProduct) {
          this.$toast.warning("Produto sem CFOP ou CSOSN ou NCM incluso!!");
          this.produtoError = true;
          this.store.currentTab = oldValue;
          return;
        }
      }
    },
    "store.formProduto": {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
        this.form.produtos = newValue.produtos;
        this.produtos = newValue.produtos;
      },
      deep: true,
    },
    preco(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.total = newValue * this.quantidade;
      }
    },
    quantidade(newValue, oldValue) {
      if (oldValue !== newValue) {
        const isAtacado =
          newValue >= this.produtoSelected.quantidade_atacado &&
          this.produtoSelected.preco_atacado > 0 &&
          this.store.formCliente.tipo_atacado;

        this.preco = isAtacado
          ? this.produtoSelected.preco_atacado
          : this.produtoSelected.preco;

        this.total = newValue * parseFloat(this.preco);
        this.produtoSelected.total = this.total;
        this.produtoSelected.tipo_atacado = isAtacado;
        this.produtoSelected.quantidade = newValue;
      }
    },
    desconto(newValue, oldValue) {
      if (oldValue !== newValue && !isNaN(newValue) && newValue !== "") {
        this.total =
          this.quantidade * parseFloat(this.preco) - parseFloat(newValue);
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    openNewProduto() {
      window.open(
        this.$router.resolve({ name: "produtosCreate" }).href,
        "_blank"
      );
    },
    async addProduto(produto) {
      if (_.isEmpty(produto)) return;
      const produtoFormatted = {
        ...produto,
        preco: this.preco,
        quantidade: this.quantidade,
        desconto: this.desconto,
        total: this.total,
      };
      this.produtos = [
        ...this.produtos,
        {
          ...produtoFormatted,
          price: this.preco,
          quantity: this.quantidade,
          total: this.total,
          discount: this.desconto,
          active: true,
          currentIndex: this.produtos.length,
          ...(produto.variavel && { combinacao: produto.combinacao }),
        },
      ];
      const subtotal = this.produtos.reduce(
        (acc, produto) =>
          acc + (produto.quantity * produto.price - produto.discount),
        0
      );
      this.form = {
        produtos: [...this.produtos],
        subtotal,
      };
      this.clearFields();
      this.produtoError = false;
    },
    clearFields() {
      this.descricao = "";
      this.estoqueProduto = 0;
      this.quantidade = 1;
      this.preco = 0;
      this.total = 0;
      this.desconto = 0;
      this.produtoSelected = null;
      this.produtoFilled = false;
    },
    openModalProduto(produtoData, currentIndex) {
      this.modalProdutoIsOpen = true;
      this.produtoToEdit = {
        ...produtoData,
        quantity: produtoData.quantity,
        price: produtoData.price,
        discount: produtoData.discount,
        currentIndex,
      };
    },
    handleDeleteProduto(item) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir o produto " + item.descricao + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const aux = this.produtos
              .filter((produto) => produto.currentIndex !== item.currentIndex)
              .map((item, index) => ({
                ...item,
                currentIndex: index,
              }));
            this.form.produtos = [...aux];
            this.produtos = [...aux];
          }
        });
    },
    closeModalProduto() {
      this.modalProdutoIsOpen = false;
    },
    setDesconto() {
      if (!this.produtoSelected) return;
      this.form.total =
        this.form.preco * this.form.quantidade - this.form.desconto;
      this.produtoSelected.total =
        parseFloat(this.produtoSelected.preco) *
          parseFloat(this.produtoSelected.quantidade) -
        this.produtoSelected.desconto;
    },
    validateProdutoState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async saveEditProduto(newEditedProduto) {
      this.form.produtos[newEditedProduto.currentIndex] = {
        ...this.form.produtos[newEditedProduto.currentIndex],
        ...newEditedProduto,
        price: newEditedProduto.price,
        quantity: newEditedProduto.quantity,
        total:
          newEditedProduto.price * newEditedProduto.quantity -
          parseFloat(newEditedProduto.discount),
        discount: parseFloat(newEditedProduto.discount),
      };

      if (this.form.produtos[newEditedProduto.currentIndex].vinculoProduto) {
        this.form.produtos[newEditedProduto.currentIndex].quantidade =
          newEditedProduto.quantity;
        this.form.produtos[newEditedProduto.currentIndex].quantidade_conversao =
          newEditedProduto.quantity;
        this.form.produtos[newEditedProduto.currentIndex].preco =
          newEditedProduto.price;
        this.form.produtos[newEditedProduto.currentIndex].preco_original =
          newEditedProduto.price;
      }
      this.form.subtotal = this.form.produtos.reduce(
        (acc, produto) =>
          acc + produto.price * produto.quantity - produto.discount,
        0
      );
      this.produtos = _.cloneDeep(this.form.produtos);
      this.store.formProduto = _.cloneDeep(this.form);
      this.modalProdutoIsOpen = false;
    },
    onSearchProduto(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchProdutos(loading, search, this);
      }
    },
    searchProdutos: _.debounce(async (loading, search, vm) => {
      vm.produtosFetched = await vm.fetchProdutos(
        search,
        vm.typeFilter,
        vm.store?.formCliente?.id_pessoa
      );
      loading(false);
    }, 350),
    setProduto(produto) {
      if (!produto) return;
      this.descricao = produto.descricao;

      if (produto?.variavel) {
        this.produtoSelected = produto;
        this.$bvModal.show("variacaoModal");
        return;
      }

      if (produto?.lote) {
        this.produtoSelected = produto;
        this.$bvModal.show("loteModal");
        this.produtosFetched = [];
        this.atualizarLotes++;
        return;
      }

      this.estoqueProduto = produto.quantidade;
      const preco_atacado = produto?.preco_atacado ?? 0;
      const qtd_atacado = produto?.quantidade_atacado ?? 0;
      this.quantidade = 1;

      const isAtacadoValue =
        this.quantidade >= qtd_atacado &&
        preco_atacado > 0 &&
        this.store.formCliente.tipo_atacado;

      if (isAtacadoValue) {
        this.preco = parseFloat(preco_atacado);
      } else this.preco = parseFloat(produto.preco);

      this.total = this.quantidade * parseFloat(this.preco);
      this.produtoSelected = {
        ...produto,
        preco: this.preco,
        quantidade: this.quantidade,
        tipo_atacado: isAtacadoValue,
      };
      this.produtoSelected.total = this.total;

      this.produtoFilled = true;
    },
  },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      const { produtos, arquivoXml } = this.$route.params.compraData;
      var produtosCompra = produtos;

      if (arquivoXml) {
        produtosCompra.forEach(function (produto, i) {
          if (produto.vinculoProduto) {
            produtos[i].codigo = produto.vinculoProduto.codigo;
            produtos[i].descricao = produto.vinculoProduto.descricao;
            produtos[i].id = produto.vinculoProduto.id;
            produtos[i].uuid = produto.vinculoProduto.uuid;
            produtos[i].variavel = false;
          }
          produtos[i].quantity = produto.quantidade_conversao;
          produtos[i].total = produto.total;
          produtos[i].price = produto.preco;
          produtos[i].discount = produto.desconto;
          produtos[i].currentIndex = i;
          produtos[i].active = true;
        });
      }

      this.produtos = produtosCompra;
      const subtotal = this.produtos.reduce(
        (acc, produto) =>
          acc + (produto.quantity * produto.price - produto.discount),
        0
      );
      this.form = {
        produtos: [...this.produtos],
        subtotal,
      };
    } else {
      this.form = new ProdutoData({});
      this.form.produtos = [];
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 3;
    },
  },
};
</script>
