var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"col":""}},[_c('b-card',{staticClass:"card-titulo-itens",attrs:{"title":"Pré-Venda em Andamento"}}),_c('b-card',{staticClass:"card-itens tableItensVenda",attrs:{"id":"tableItensVenda"}},[_c('table',{staticClass:"table table-head-custom table-head-bg table-vertical-center"},[_c('thead',{staticClass:"tableHeader"},[_c('tr',[_c('th'),_c('th',{staticClass:"pl-0 font-weight-bold text-muted pl-7"},[_vm._v(" Produto"),_c('br'),_vm._v("Código de Barras ")]),_c('th',{staticClass:"text-right pr-2 font-weight-bold text-muted"},[_vm._v(" Quant. ")]),_c('th',{staticClass:"text-right pr-0 font-weight-bold text-muted"},[_vm._v(" Unitário ")]),_c('th',{staticClass:"text-right pr-0 font-weight-bold text-muted pr-7"},[_vm._v(" Total ")]),_c('th',{staticClass:"text-right pr-0 font-weight-bold text-muted pr-7"})])]),_c('tbody',_vm._l((_vm.reversedArray),function(i,index){return _c('tr',{key:'item' + index,class:!i.active
                ? 'font-weight-boldest font-size-lg cancelado'
                : 'font-weight-boldest font-size-lg'},[_c('td',[_vm._v(_vm._s(parseFloat(index + 1))+" / "+_vm._s(i.product.codigo))]),_c('td',{staticClass:"pl-0"},[_c('a',{staticClass:"text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg pl-7",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(i.product.descricao)+" "),(i.product.tipo_atacado)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                    'Item atendeu a regra de preço de atacado'
                  ),expression:"\n                    'Item atendeu a regra de preço de atacado'\n                  ",modifiers:{"hover":true,"right":true}}]},[_c('InformationOutline',{attrs:{"size":14}})],1):_vm._e()]),_c('span',{staticClass:"text-muted font-weight-bold d-block pl-7"},[_vm._v(" "+_vm._s(i.product.cod_barras)+" ")])]),_c('td',{staticClass:"text-right pr-0 pt-7 quantity"},[(i.active && !i.product.lote)?_c('a',{staticClass:"btn btn-xs btn-light-success btn-icon mr-2",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.substract(_vm.store.productOfTradeCollection.length - 1 - index)}}},[_c('i',{staticClass:"ki ki-minus icon-xs"})]):_vm._e(),_c('span',{staticClass:"mr-2 font-weight-bolder"},[_vm._v(_vm._s(_vm.numberToReal(i.quantity, "quantidade")))]),(i.active && !i.product.lote)?_c('a',{staticClass:"btn btn-xs btn-light-success btn-icon",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.add(_vm.store.productOfTradeCollection.length - 1 - index)}}},[_c('i',{staticClass:"ki ki-plus icon-xs"})]):_vm._e()]),_c('td',{staticClass:"text-right pr-0 pt-7"},[_vm._v(" R$ "+_vm._s(_vm.numberToReal(i.product.preco, "price"))+" ")]),_c('td',{staticClass:"text-right pr-0 pr-7 pt-7"},[_vm._v(" R$ "+_vm._s(_vm.numberToReal(i.totalPrice, 2))+" ")]),_c('td',{staticClass:"text-right pr-0 pr-7 pt-7"},[_c('button',{staticClass:"btn btn-sm btn-danger btn-hover-danger btn-icon mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeItem(
                    _vm.store.productOfTradeCollection.length - 1 - index
                  )}}},[_c('TrashCanIcon',{attrs:{"size":16}})],1)])])}),0)])]),_c('blockquote')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }