<template>
  <div style="position: relative">
    <button
      style="position: absolute; left: 55px; top: -1px"
      @click="inutilizar()"
      class="btn btn-sm btn-warning btn-hover-danger btn-icon mr-2"
      v-b-tooltip="'Inutilizar numeração'"
    >
      <CancelIcon :size="16" />
    </button>
    <div style="position: absolute; left: 100px" v-if="mensagemPreVenda">
      <p class="mb-0 font-weight-bolder">
        <Warning :size="20" />
        {{ mensagemPreVenda }}
      </p>
    </div>
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Número',
          field: 'numero',
        },
        {
          type: 'text',
          label: 'Cliente',
          field: 'nomeCliente',
        },
        {
          type: 'date',
          label: 'Data de emissão',
          field: 'emissao',
        },
        {
          type: 'select',
          label: 'Status',
          field: 'status',
          options: [
            'Todos',
            'Autorizada',
            'Finalizada',
            'Cancelada',
            'Editando',
            'Inutilizada',
            'Rejeitada',
          ],
        },
      ]"
    />
    <b-row class="px-4"> </b-row>
    <TableApp
      titulo="Vendas Realizadas"
      acaoBtn="/#/venda-nova"
      textoBtn="PDV"
      api-url="/nfe/lista"
      :fields="fields"
      classFilterColumn="vendas"
      ref="vendas"
      :perPage="30"
    >
      <template slot="customRow" scope="props">
        <span slot="numero" class="pl-0 pt-8" v-if="props.field == 'numero'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{
                props.rowData.statusTransmissao === 4 ||
                props.rowData.statusTransmissao === 3 ||
                props.rowData.statusTransmissao === 14
                  ? "NFC-e "
                  : "Pré-venda "
              }}
              {{ props.rowData.numero }}
              <p
                style="margin-bottom: 0rem; !important"
                v-if="props.rowData.sync_app"
                v-b-tooltip.right="'NFc-e realizada no APP Nuts'"
              >
                <Mobile :size="15" />
              </p>
            </div>
          </div>
        </span>
        <span class="pl-0 pt-8" slot="emissao" v-if="props.field == 'emissao'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ formatDate(props.rowData.emissao) }}
            </div>
          </div>
        </span>
        <span
          class="pl-0 pt-8"
          slot="nomeCliente"
          v-if="props.field == 'nomeCliente'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.nomeCliente }}
            </div>
          </div>
        </span>
        <span class="pl-0 pt-8" slot="status" v-if="props.field == 'status'">
          <span
            v-if="props.rowData.status == EDITANDO"
            class="label label-lg label-inline label-light-primary"
            >Editando</span
          >
          <span
            v-else-if="props.rowData.status == FINALIZADA"
            class="label label-lg label-inline label-light-success"
            >Finalizada</span
          >
          <span
            v-else-if="props.rowData.statusTransmissao == AUTORIZADA"
            class="label label-lg label-inline label-light-success"
            >Autorizada</span
          >
          <span
            v-else-if="props.rowData.status == CANCELADA"
            class="label label-lg label-inline label-light-danger"
            >Cancelada</span
          >
          <span
            v-else-if="props.rowData.status == DENEGADA"
            class="label label-lg label-inline label-light-warning"
            >Denegada</span
          >
          <span
            v-else-if="props.rowData.status == PROCESSAMENTO"
            class="label label-lg label-inline label-light-danger"
            >Em processamento</span
          >
          <span
            v-else-if="props.rowData.statusTransmissao == INUTILIZADA"
            class="label label-lg label-inline label-light-dark"
            >Inutilizada</span
          >
          <span v-else class="label label-lg label-inline label-light-primary"
            >Editando</span
          >
        </span>
        <span
          slot="chave"
          class="pl-0 pt-3"
          v-if="
            props.rowData.statusTransmissao == AUTORIZADA &&
            props.rowData.numero &&
            props.field == 'chave' &&
            props.rowData.status != EDITANDO
          "
        >
          <div class="d-flex align-items-rigth">
            <a
              v-b-tooltip="'Clique para consultar a venda'"
              @click="consultar(props.rowData)"
              class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
              >{{ props.rowData.chave.slice(1, 10) }}...</a
            >
          </div>
        </span>
        <span
          slot="valorTotal"
          v-if="props.field == 'valorTotal'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg"
        >
          R$
          {{ numberToReal(props.rowData.valorTotal, 2) }}
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <router-link
                v-b-tooltip="'Editar Pré-venda'"
                v-show="props.rowData.status == EDITANDO"
                class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                title="Editar"
                :to="{
                  name: 'vendasEditar',
                  params: {
                    id: props.rowData.id,
                  },
                }"
              >
                <Pencil :size="16" />
              </router-link>
              <button
                v-show="props.rowData.status == FINALIZADA"
                @click="imprimirVenda(props.rowData)"
                class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
                v-b-tooltip="'Imprimir Pré-venda'"
              >
                <PrintIcon :size="16" />
              </button>
              <button
                v-show="
                  props.rowData.statusTransmissao == AUTORIZADA &&
                  props.rowData.status != EDITANDO
                "
                @click="imprimirNota(props.rowData)"
                class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
                v-b-tooltip="'Imprimir NFce'"
              >
                <PrintIcon :size="16" />
              </button>
              <button
                v-show="
                  (props.rowData.statusTransmissao == CANCELADA &&
                    props.rowData.numero &&
                    props.rowData.xmlCancelado) ||
                  props.rowData.statusTransmissao == AUTORIZADA ||
                  props.rowData.statusTransmissao == INUTILIZADA
                "
                @click="gerarlXml(props.rowData)"
                class="btn btn-sm btn-warning btn-hover-warning btn-icon mr-2"
                v-b-tooltip="'Gerar XML'"
              >
                <XMLIcon :size="16" />
              </button>
              <button
                v-show="
                  props.rowData.status == FINALIZADA ||
                  props.rowData.status == EDITANDO
                "
                @click="
                  () => {
                    getRequestAdmin(() => cancelVenda(props.rowData));
                  }
                "
                class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                v-b-tooltip="'Cancelar Pré-venda'"
              >
                <CancelIcon :size="16" />
              </button>
              <button
                v-show="
                  props.rowData.statusTransmissao == AUTORIZADA &&
                  !props.rowData.isPDVSync &&
                  props.rowData.status != EDITANDO
                "
                @click="cancel(props.rowData)"
                class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                v-b-tooltip="'Cancelar NFCe'"
              >
                <CancelIcon :size="16" />
              </button>
              <button
                v-show="
                  props.rowData.status == FINALIZADA ||
                  props.rowData.status == EDITANDO
                "
                @click="transmitir(props.rowData)"
                class="btn btn-sm btn-info btn-hover-success btn-icon mr-2"
                v-b-tooltip="'Transmitir Pré-venda'"
              >
                <TransmitIcon :size="16" />
              </button>
              <button
                @click="shareToWhatsapp(props.rowData)"
                v-if="
                  props.rowData.statusTransmissao === 4 ||
                  props.rowData.status == FINALIZADA
                "
                v-b-tooltip.righttop="
                  'Encaminhar XML e PDF para o whatsapp do cliente'
                "
                class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
              >
                <Whatsapp :size="20" />
              </button>
              <button
                @click="sendToEmail(props.rowData)"
                v-if="props.rowData.statusTransmissao === 4"
                v-b-tooltip="'Enviar NFCe por email'"
                class="btn btn-sm btn-info btn-hover-success btn-icon mr-2"
              >
                <Email :size="20" v-if="!isLoading" />
              </button>
            </span>
          </div>
        </span>
      </template>
    </TableApp>

    <b-modal
      id="selectEmailModal"
      title="Enviar NFCe para email(s)"
      size="md"
      centered
    >
      <div class="mx-auto">
        <b-form-group
          label="Email"
          label-for="email"
          v-if="userEmailsToSend.length > 0"
        >
          <VueSelect
            ref="emailToSend"
            :filterable="false"
            :options="userEmailsToSend"
            v-model="selectedEmail"
          >
            <template slot="no-options"> Informe o E-mail </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.label }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.label }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>

        <b-form-group
          v-else
          id="selectedEmail"
          label-for="selectedEmail"
          label="Email"
        >
          <b-form-input v-model="selectedEmail" />
        </b-form-group>

        <b-form-group
          id="ccEmail"
          label-for="ccEmail"
          label="Cópia para: (opcional)"
        >
          <b-form-input v-model="ccEmail" autofocus />
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" @click="enviarEmail" ref="enviar-nfe-email">
          Enviar
        </b-button>
      </template>
    </b-modal>

    <SendToWppModal
      :openModal="wppOpenModal"
      :data="dataToWhatsapp"
      @handleOpenModal="(openModal) => (wppOpenModal = openModal)"
      module="nfce"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PrintIcon from "vue-material-design-icons/Printer";
import XMLIcon from "vue-material-design-icons/FileDocument";
import CancelIcon from "vue-material-design-icons/FileCancel";
import TransmitIcon from "vue-material-design-icons/SendCheckOutline";
import TableApp from "@/components/Table/Table2";
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import swal from "sweetalert";
import { SistemaMixin } from "@/modules/Application";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import Pencil from "vue-material-design-icons/Pencil.vue";
import FilterFields from "@/components/FilterFields";
import SendToWppModal from "@/components/SendToWppModal";
import Email from "vue-material-design-icons/Email.vue";
import VueSelect from "vue-select";
import Warning from "vue-material-design-icons/AlertCircleOutline";
import Mobile from "vue-material-design-icons/CellphoneCheck.vue";
// import CartOutline from "vue-material-design-icons/FileDocumentOutline.vue";

export default {
  name: "app-vendas",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    PrintIcon,
    XMLIcon,
    CancelIcon,
    TransmitIcon,
    Pencil,
    Whatsapp,
    FilterFields,
    SendToWppModal,
    Email,
    VueSelect,
    Warning,
    Mobile,
    // CartOutline,
  },
  data() {
    return {
      components: {
        flatPickr,
      },
      wppOpenModal: false,
      dataToWhatsapp: null,
      mensagemPreVenda: "",
      fields: [
        {
          label: "N°",
          field: "numero",
          type: "text",
          slot: true,
          sortable: false,
          width: "15%",
        },
        {
          label: "Dt/Emissão",
          field: "emissao",
          type: "date",
          filterable: true,
          sortable: false,
          slot: true,
          width: "2%",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Cliente",
          field: "nomeCliente",
          sortable: false,
          filterable: true,
          slot: true,
          type: "text",
          width: "20%",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Chave Acesso",
          field: "chave",
          sortable: false,
          slot: true,
          width: "3%",
        },
        {
          label: "Valor Total",
          field: "valorTotal",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "25%",
        },
      ],
      form: {},
      serie: null,
      isLoading: false,
      userEmailsToSend: [],
      selectedEmail: null,
      nfeIdToSendEmail: null,
      ccEmail: null,
    };
  },
  created() {
    this.FINALIZADA = 7;
    this.EDITANDO = 1;
    this.AUTORIZADA = 4;
    this.CANCELADA = 3;
    this.DENEGADA = 4;
    this.PROCESSAMENTO = 5;
    this.INUTILIZADA = 14;
  },
  methods: {
    shareToWhatsapp(data) {
      this.wppOpenModal = true;
      this.dataToWhatsapp = data;
    },
    imprimirNota(data) {
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/printNota", {
            id: data.id,
          })
          .then((result) => {
            const resultData = result["data"]["danfe-url"];
            if (resultData) {
              window.open(resultData, "_blank");
            }
            if (result["data"]["voucher-url"]) {
              window.open(result["data"]["voucher-url"]);
            }
            if (result["data"]["duplicata"]) {
              window.open(result["data"]["duplicata"]);
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Venda impressa com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    imprimirComprovanteConsignado(data) {
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/comprovante-consignado", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["venda-url"]) {
              window.open(result["data"]["venda-url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Comprovante impresso com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    imprimirVenda(data) {
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/printVenda", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["venda-url"]) {
              window.open(result["data"]["venda-url"], "_blank");
            }

            if (result["data"]["voucher-url"]) {
              window.open(result["data"]["voucher-url"]);
            }

            if (result["data"]["duplicata"]) {
              window.open(result["data"]["duplicata"]);
            }

            swal.stopLoading();
            swal.close();
            this.$toast.success("Venda impressa com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarlXml(data) {
      swal({
        title: "Deseja realizar a visualização do xml?",
        text: "Aguarde, pois será gerado o xml automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/xml", {
            id: data.id,
          })
          .then((result) => {
            const resultData = result["data"]["xml-url"];
            if (resultData) {
              window.open(resultData, "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Xml gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    transmitir(data) {
      swal({
        title: "Deseja realizar a transmissão da venda?",
        text: "Aguarde, pois será enviada a venda para transmissão!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/transmitter", {
            id: data.id,
          })
          .then((result) => {
            if (result["data"]["nfe-url"]) {
              window.open(result["data"]["nfe-url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$refs.vendas.refresh();
            this.$toast.success("Nota fiscal gerada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    cancel(data) {
      this.getRequestAdmin(() => {
        swal({
          title: "Deseja cancelar a NFc-e?",
          text: "Informe a justificativa do cancelamento!",
          icon: "info",
          content: "input",
          buttons: {
            cancel: {
              text: "Cancelar",
              value: "cancel",
              visible: true,
              closeModal: true,
            },
            confirm: {
              text: "OK",
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: false,
          closeModal: false,
          closeOnClickOutside: false,
        }).then(async (value) => {
          if (value === "cancel") {
            swal.close();
            swal.stopLoading();
            return;
          }

          if (value.length < 15) {
            swal.stopLoading();
            this.$toast.warning(
              "A justificativa tem que possuir no mínimo 15 caracteres"
            );
            return;
          }

          await this.$http
            .post("/nfe/cancel", {
              id: data.id,
              justificativa: value,
            })
            .then(() => {
              swal.stopLoading();
              swal.close();
              this.$toast.success("NFC-e cancelada com sucesso!");
              this.$refs.vendas.refresh();
            })
            .catch(() => {
              swal.stopLoading();
              swal.close();
            });
        });
      });
    },

    cancelVenda(data) {
      swal({
        title: "Deseja cancelar a venda?",
        text: "Ao cancelar irá estornar os valores ao estoque!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        await this.$http
          .post("/nfe/cancelVenda", {
            id: data.id,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Venda cancelada com sucesso!");
            this.$refs.vendas.refresh();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    inutilizar() {
      this.$swal
        .fire({
          title: "Deseja inutilizar numeração de notas?",
          text: "Informe a justificativa e a numeração desejada!",
          icon: "info",
          html: `
      <input type="text" id="from" class="swal2-input" placeholder="De">
      <input type="text" id="to" class="swal2-input" placeholder="Até">
      <input type="text" id="justificativa" class="swal2-input" placeholder="Justificativa">`,
          confirmButtonText: "Enviar",
          focusConfirm: false,
          showCancelButton: true,
          preConfirm: async () => {
            const from = this.$swal.getPopup().querySelector("#from").value;
            const to = this.$swal.getPopup().querySelector("#to").value;
            const justificativa = this.$swal
              .getPopup()
              .querySelector("#justificativa").value;

            if (!justificativa || !to || !from) {
              this.$toast.warning("Preencha todos campos necessários");
              return;
            }
            if (justificativa < 15) {
              this.$toast.warning(
                "A justificativa tem que possuir no mínimo 15 caracteres"
              );
              return;
            }
            await this.$http
              .post("/nfe/inutilizar", {
                from: from,
                to: to,
                justificativa: justificativa,
                modelo: 65,
                serie: this.serie,
              })
              .then(() => {
                this.$toast.success("Notas inutilizadas com sucesso!");
                this.$refs.vendas.refresh();
              })
              .catch(() => {});
          },
        })
        .then(() => {});
    },
    consultar(data) {
      swal({
        title: "Deseja consultar a venda?",
        text: "Aguarde pois será consultado a venda nos servidores!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/consult", {
            id: data.id,
          })
          .then((result) => {
            swal.stopLoading();
            swal.close();
            this.$toast.success(result.data.msg);
            this.$copyText(data.chave);
            this.$refs.vendas.refresh();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    async sendToEmail({ identificacaoCliente, id }) {
      this.isLoading = true;
      this.userEmailsToSend = [];
      try {
        swal({
          title: "Deseja enviar uma cópia da NFCe por email?",
          text: "Aguarde, pois será redirecionado em breve",
          icon: "info",
          buttons: {
            cancel: {
              text: "Cancelar",
              value: "cancel",
              visible: true,
              closeModal: true,
            },
            confirm: {
              text: "OK",
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: false,
          closeModal: false,
          closeOnClickOutside: false,
        }).then(async (value) => {
          if (value === "cancel") {
            swal.close();
            swal.stopLoading();
            return;
          }
          try {
            const response = await this.$http.post("/pessoa/get-emails", {
              cnpj_cpf: identificacaoCliente,
            });
            swal.close();
            swal.stopLoading();
            if (response.data.emails.length > 0) {
              this.userEmailsToSend = response.data.emails.map(
                (item) => item.email
              );
            }
            this.nfeIdToSendEmail = id;
            this.$bvModal.show("selectEmailModal");
          } catch (error) {
            console.log(error);
            swal.close();
            swal.stopLoading();
          }
        });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async enviarEmail() {
      this.addLoadingToButton("enviar-nfe-email");
      try {
        await this.$http.post("/nfe/send-emails", {
          id: this.nfeIdToSendEmail,
          email: this.selectedEmail,
          email_cc: this.ccEmail,
        });
        this.$toast.success("Email enviado com sucesso");
      } catch (error) {
        console.log(error);
      }
      this.$bvModal.hide("selectEmailModal");
      this.ccEmail = null;
      this.nfeIdToSendEmail = null;
      this.selectedEmail = null;
      this.userEmailsToSend = [];
      this.removeLoadingToButton("enviar-nfe-email");
    },
  },
  dateRangeFilter(data, filterString) {
    let dateRange = filterString.split("to");
    let startDate = Date.parse(dateRange[0]);
    let endDate = Date.parse(dateRange[1]);
    return (data =
      Date.parse(data) >= startDate && Date.parse(data) <= endDate);
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    const { data } = await this.$http.post("/configuracoes/current-config", {
      modelo: 65,
    });
    if (data.length > 0) {
      this.serie = data[0].serie;
    }

    const response = await this.$http.post("/nfe/verifica-prevendas");
    this.mensagemPreVenda = response?.data.mensagem ?? "";

    let inputs = ['input[placeholder="Data"]'];
    inputs.forEach(function (input) {
      flatPickr(input, {
        dateFormat: "Y-m-d",
        allowInput: true,
        locale: Portuguese,
      });
    });
  },
};
</script>

<style>
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: var(--primary-color) !important;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}
</style>
