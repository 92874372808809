<template>
  <div>
    <b-row>
      <b-col md="12" style="height: 100% !important">
        <b-card
          class="card-itens tableItensCompra"
          style="max-height: 100% !important"
          id="tableItensCompra"
        >
          <table
            class="table table-head-custom table-head-bg table-vertical-center"
            :key="keyToRender"
          >
            <thead>
              <tr>
                <th></th>
                <th class="pl-0 font-weight-bold text-muted pl-7">
                  Itens Nota
                </th>
                <th class="pl-0 font-weight-bold text-muted pl-7">
                  Custo Últ.Compra
                </th>
                <th class="pl-0 font-weight-bold text-muted pl-7">
                  Custo Atual
                </th>
                <th class="pl-0 font-weight-bold text-muted pl-7">
                  Preço Venda
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(i, index) in items" :key="'item' + index">
                <td style="font-size: 12px; width: 20px">
                  {{ parseFloat(index + 1) }}
                </td>
                <td class="pl-0" style="font-size: 12px; width: 400px">
                  <div>
                    <span
                      class="mb-0"
                      v-b-tooltip.hover.left
                      :title="'Referência: ' + i.cod_importacao"
                      ><strong>{{ i.cod_importacao }}</strong> -
                      {{ i.produto.descricao }}</span
                    >
                    <span
                      class="text-success ml-5 border border-success p-1 pr-2 pl-2 rounded-lg"
                      v-if="i?.vinculo"
                      >Vinculado</span
                    >
                    <span
                      class="text-info ml-5 border border-info p-1 pr-4 pl-4 rounded-lg"
                      v-if="!i?.vinculo"
                      v-b-tooltip.hover.right
                      :title="'Produtos não vinculados serão cadastrados no sistema como produtos novos. Se você já tem esse produto cadastrado, clique em “Vincular” e faça o vinculo com esse item'"
                      >Novo</span
                    >
                    <span
                      v-if="i?.vinculo"
                      class="text-muted"
                      style="font-size: 12px"
                    >
                      {{ i.vinculoProduto.codigo }} -
                      {{ i.vinculoProduto.descricao }}</span
                    >
                  </div>
                </td>
                <td style="width: 150px">
                  <money
                    disabled
                    v-if="i.vinculo"
                    class="form-control"
                    :value="parseFloat(i.custo_ultima_compra)"
                    @input="(value) => (i.custo_ultima_compra = value)"
                    v-bind="priceOptions"
                  ></money>
                </td>
                <td
                  style="width: 150px"
                  v-b-tooltip.hover.left="
                    'Soma do valor unitário do item + Frete + IPI + Despesas + ICMS ST - Descontos'
                  "
                >
                  <money
                    disabled
                    class="form-control"
                    :value="parseFloat(i.custo)"
                    @input="(value) => (i.custo = value)"
                    v-bind="priceOptions"
                  ></money>
                </td>
                <td style="width: 150px">
                  <money
                    v-if="!i.vinculo"
                    class="form-control"
                    :value="parseFloat(i.preco)"
                    @input="(value) => (i.preco = value)"
                    v-bind="priceOptions"
                  ></money>
                  <money
                    :disabled="i.vinculoProduto.markup > 0"
                    v-else
                    class="form-control"
                    :value="parseFloat(i.vinculoProduto.preco)"
                    @input="(value) => (i.vinculoProduto.preco = value)"
                    v-bind="priceOptions"
                  ></money>
                </td>
                <td style="width: 100px">
                  <b-button
                    variant="primary"
                    href="javascript:;"
                    @click="handleOpenVinculo(i, index)"
                  >
                    {{ !i?.vinculo ? "Vincular" : "Desvincular" }}
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
        <blockquote />
      </b-col>
    </b-row>
    <b-modal
      id="vincular-itens"
      title="Vincular/Desvincular item"
      size="md"
      centered
    >
      <h5 style="text-align: center; margin-bottom: 2rem">
        Busque o produto que deseja vincular com o item:
        <span style="font-weight: normal">{{
          produtoParaVincular?.descricao
        }}</span>
      </h5>
      <VueSelect
        ref="buscarProduto"
        :filterable="false"
        :options="produtos"
        @search="onSearchProduto"
        @input="handleSelectProduto"
        :value="produtoParaVincular.vinculoProduto"
        v-if="produtoParaVincular"
        :disabled="produtoParaVincular.vinculoProduto"
      >
        <template slot="no-options"> Informe a descrição do produto </template>

        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ option.codigo }} - {{ option.descricao }}
          </div>
        </template>

        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.codigo }} - {{ option.descricao.slice(0, 30) }}
          </div>
        </template>
      </VueSelect>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          @click="
            () => {
              cancel();
            }
          "
        >
          Cancelar
        </b-button>
        <b-button
          v-if="vinculoProduto"
          variant="danger"
          ref="desvincular-button"
          @click="
            () => {
              desvincularItem();
              ok();
            }
          "
        >
          Desvincular
        </b-button>
        <b-button
          variant="success"
          @click="
            () => {
              vincularItem();
              ok();
            }
          "
          v-if="!vinculoProduto"
          ref="vincular-button"
        >
          Vincular
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { SistemaMixin } from "@/modules/Application";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Money } from "v-money";
import { priceOptions } from "@/modules/Application";
import { mapActions } from "vuex";

export default {
  name: "compra-card-itens",
  components: {
    VueSelect,
    Money,
  },
  mixins: [SistemaMixin],
  data() {
    return {
      calculoTotal: 0,
      produtos: [],
      priceOptions,
      produtoParaVincular: null,
      keyToRender: 0,
      selectedItem: 0,
      vinculoProduto: null,
    };
  },
  props: {
    items: {
      default: [],
    },
    total: {
      default: 0,
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async vincularItem() {
      if (!this.produtoParaVincular) {
        this.$toast.warning("Selecione um produto para vincular!");
        return;
      }
      this.setLoading(true);
      try {
        const data = {};
        data.codigo_importacao = this.produtoParaVincular.cod_importacao;
        data.id = this.produtoParaVincular.vinculoProduto.id ?? null;
        await this.$http.post("/produtos/vincular-produto", data);
        this.items[this.produtoParaVincular.index].vinculo = true;
        this.items[this.produtoParaVincular.index].vinculoProduto =
          this.produtoParaVincular.vinculoProduto;
        this.$emit("onItemsChange", this.items);
        this.$toast.success("Produto vinculado com sucesso!");
        this.keyToRender = this.keyToRender + 1;
      } catch (error) {
        console.log(error);
      }
      this.setLoading(false);
    },
    async desvincularItem() {
      if (!this.produtoParaVincular) {
        this.$toast.warning("Este item não possui vínculo!");
        return;
      }
      this.setLoading(true);
      try {
        const data = {};
        data.codigo_importacao = null;
        data.id = this.produtoParaVincular?.vinculoProduto?.id ?? null;
        await this.$http.post("/produtos/desvincular-produto", data);
        this.items[this.produtoParaVincular.index].vinculo = false;
        this.items[this.produtoParaVincular.index].vinculoProduto = null;
        this.$toast.success("Produto desvinculado com sucesso!");
        this.keyToRender = this.keyToRender + 1;
        this.$emit("onItemsChange", this.items);
        this.produtoParaVincular = null;
      } catch (error) {
        console.log(error);
      }
      this.setLoading(false);
    },
    handleOpenVinculo(produto, index) {
      this.selectedItem = index;
      this.produtoParaVincular = {
        ...produto,
        index,
        descricao: produto.produto.descricao,
      };
      if (produto?.vinculoProduto) this.vinculoProduto = true;
      else this.vinculoProduto = false;
      this.$bvModal.show("vincular-itens");
    },
    handleSelectProduto(produto) {
      this.produtoParaVincular = {
        ...this.produtoParaVincular,
        vinculoProduto: produto,
      };
    },
    onSearchProduto(search, loading) {
      if (search.length) {
        loading(true);
        this.searchProduto(loading, search, this);
      }
    },
    searchProduto: _.debounce(async (loading, search, vm) => {
      vm.produtos = await vm.getProdutoByDescricao(search);
      loading(false);
    }, 350),
    valorReal(valor = 0) {
      return valor.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
  },
  watch: {
    total(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.calculoTotal = newValue;
      }
    },
  },
};
</script>

<style>
.produtoBusca .vs__dropdown-menu {
  width: 700px;
  left: -200px !important;
}

.card-titulo-itens .card-title {
  margin-bottom: 0 !important;
  font-weight: 700;
  font-size: 1.75rem;
  padding: 0.75rem;
}

.card-titulo-itens .card-body {
  padding: 0.45rem;
}

.card-titulo-itens {
  background-color: #fff;
  border: 0px;
  text-align: center;
  margin-bottom: 0px;
  padding: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.card-itens .card-body {
  padding: 0;
}

.table {
  border-bottom: solid 0px #dedede;
  margin-bottom: 0px;
}

.tableItensCompra {
  /* padding: 10px; */
  border: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.tableItensCompra th,
.tableItensCompra td {
  padding: 8px 16px;
}

.tableItensCompra .table tr th {
  border-bottom: 2px solid var(--color-primary);
}

.tableItensCompra th {
  position: sticky;
  top: 0;
  background: #fff;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 1px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 200px rgb(229 245 234 / 19%);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary);
}

.card-total-venda {
  background: transparent;
  border: 0px;
  top: 30px;
}

.card-total-venda .card-body {
  padding: 0rem 1rem 0rem 1rem;
}

.card-total-left {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
  border-radius: 0px;
  border-bottom-left-radius: 20px !important;
  height: 90px;
  width: 101%;
  display: table;
  text-align: left;
}

.card-total-left .card-body {
  display: table-cell;
  vertical-align: middle;
}

.card-total-right {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #fff;
  border-radius: 0px;
  align-items: flex-end;
  border-bottom-right-radius: 20px !important;
  height: 90px;
  width: 100%;
  display: table;
  text-align: center;
  text-align: right;
}

.card-total-right .card-body {
  display: table-cell;
  vertical-align: middle;
}

.card-total-right p,
.card-total-left p {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.card-total-right h5,
.card-total-left h5 {
  font-size: 1.75rem !important;
  margin-bottom: 0;
}

.tableItensCompra tbody tr {
  border-top-width: 1.5px;
  border-top-color: var(--color-primary);
}

blockquote {
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 1;
}

blockquote:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 29px;
  right: 0;
  bottom: -24px;
  left: 0;
  padding-top: 10px;
  background: linear-gradient(-45deg, transparent 70%, white 75%),
    linear-gradient(45deg, transparent 70%, white 75%);
  background-repeat: repeat-x;
  background-size: 25px 20px, 25px 20px;
  background-position: center;
}
</style>
