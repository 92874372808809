<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <KTHeaderMobile></KTHeaderMobile>

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <KTHeader></KTHeader>
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <KTSubheader
            v-if="subheaderDisplay && displaySubheaderOnDashboard"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <transition name="fade">
                <Loading v-if="isLoading" />
              </transition>
              <div class="d-lg-flex flex-row-fluid">
                <KTAside v-if="asideEnabled"></KTAside>
                <div class="content-wrapper flex-row-fluid">
                  <transition name="fade-in-up">
                    <router-view />
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>

    <OnboardingModal
      v-if="showOnboardModal"
      :show="showOnboardModal"
      @recusou="recusarOnboarding"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import Loading from "@/components/Loading";
import { onboardStore } from "../../onboardStore";
import { SistemaMixin } from "@/modules/Application";
import OnboardingModal from "@/components/Onboarding/OnboardingModal.vue";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTSubheader,
    KTFooter,
    KTScrollTop,
    Loader,
    Loading,
    OnboardingModal,
  },
  mixins: [SistemaMixin],
  data() {
    return {
      onboardStore,
      showOnboardModal: false,
    };
  },
  beforeMount() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    if (localStorage.currentStep)
      this.onboardStore.currentStep = parseInt(localStorage.currentStep);

    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {
    footerLayout(type) {
      return this.layoutConfig("footer.layout") === type;
    },
    aceitarOnboarding() {
      this.onboardStore.currentStep = 2;
      this.showOnboardModal = false;
    },
    async recusarOnboarding() {
      this.onboardStore.currentStep = -1;
      this.showOnboardModal = false;

      await this.$http.post("/usuario/onboarding", {
        onboarding: false,
        id_usuario: this.currentUser.id_usuario,
      });
    },
  },
  watch: {
    currentUser(newValue) {
      if (newValue?.onboarding !== undefined && !newValue.onboarding) {
        this.onboardStore.currentStep = -1;
      }

      if (newValue?.onboarding && this.onboardStore.currentStep === 1) {
        this.showOnboardModal = true;
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "itsblocked",
    ]),
    ...mapState(["loading"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),

    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    contentFluid() {
      return false;
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    },
    isLoading() {
      return this.loading.loading;
    },
  },
};
</script>
