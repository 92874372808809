<template>
  <TableApp
    titulo="Cadastro de Configurações"
    subTitulo="Cadastre suas configurações para emissão de documentos"
    acaoBtn="/#/configuracoes/novo"
    textoBtn="Nova"
    api-url="/configuracoes"
    :fields="fields"
    classFilterColumn="configuracoes"
    ref="configuracoes"
    :perPage="10"
  >
    <template slot="customRow" scope="props">
      <span slot="ambiente" v-if="props.field == 'ambiente'">
        <span
          v-if="props.rowData.ambiente == 1"
          class="label label-lg label-inline label-light-success"
          >Produção</span
        >
        <span v-else class="label label-lg label-inline label-light-warning"
          >Homologação</span
        >
      </span>
      <span slot="modelo" v-if="props.field == 'modelo'">
        <div class="font-weight-bold">
          {{ props.rowData.modelo }}
        </div>
      </span>
      <span slot="serie" v-if="props.field == 'serie'">
        <div class="font-weight-bold">
          {{ props.rowData.serie }}
        </div>
      </span>
      <span slot="proximaNota" v-if="props.field == 'proximaNota'">
        <div class="font-weight-bold">
          {{ props.rowData.proximaNota }}
        </div>
      </span>
      <span slot="ativo" v-if="props.field == 'ativo'">
        <span
          v-if="props.rowData.ativo == true"
          class="label label-lg label-inline label-light-success"
          >Ativo</span
        >
        <span v-else class="label label-lg label-inline label-light-danger"
          >Inativo</span
        >
      </span>
      <span slot="actions" v-if="props.field == 'actions'">
        <div class="row-actions" style="text-align: right !important">
          <span style="overflow: visible; position: relative; width: 130px">
            <div class="dropdown dropdown-inline">
              <router-link
                class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                :to="{
                  name: 'configuracoesEditar',
                  params: {
                    id: props.rowData.id,
                  },
                }"
              >
                <Pencil :size="16" title="Editar" />
              </router-link>
              <button
                @click="excluir(props.rowData)"
                class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
              >
                <TrashCanIcon :size="16" />
              </button>
            </div>
          </span>
        </div>
      </span>
    </template>
  </TableApp>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import {
  SistemaMixin,
} from "@/modules/Application";
import { onboardStore } from '../../../onboardStore';

export default {
  name: "app-un",
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
  },
  mixins: [SistemaMixin],
  data() {
    return {
      onboardStore,
      fields: [
        {
          label: "Ambiente",
          field: "ambiente",
          type: "text",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Modelo",
          field: "modelo",
          type: "text",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Série",
          field: "serie",
          type: "text",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "N° documento Fiscal",
          field: "proximaNota",
          type: "text",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Ativo",
          field: "ativo",
          type: "text",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title:
            "Excluir a configuração com número de documento: " +
            data.proximaNota +
            "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/configuracoes/delete", {
              id: data.id,
            });
            this.$toast.success("Configuração excluído!");
            this.$refs.configuracoes.refresh();
          }
        });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
