<template>
  <div>
    <h4>Estoque</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Movimentação do item:</h4>
              <b-row>
                <b-col md="12">
                  <VueSelect
                    placeholder="Informe a descrição do produto"
                    ref="buscarProduto"
                    label="id"
                    :filterable="false"
                    @input="setProduto"
                    v-model="valueProduto"
                    :options="produtos"
                    @search="onSearchProduto"
                  >
                    <template slot="no-options">
                      Informe a descrição do produto
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_movimentacao"
                    label-for="data_inicial_movimentacao"
                    label="Data Inicial"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_inicial_movimentacao"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_movimentacao"
                    label-for="data_final_movimentacao"
                    label="Data final"
                  >
                    <b-form-input type="date" v-model="data_final_movimentacao">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <br />
              <button
                @click="gerarMovimentacaoProduto()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Lista de preços:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="imprimir_custo"
                    label-for="imprimir_custo"
                    label="Imprimir Custo"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setImprimirCusto"
                          :checked="imprimir_custo"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="imprimir_grupo"
                    label-for="imprimir_grupo"
                    label="Imprimir Grupo"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setImprimirGrupo"
                          :checked="imprimir_grupo"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="imprimir_preco"
                    label-for="imprimir_preco"
                    label="Imprimir Preço"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setImprimirPreco"
                          :checked="imprimir_preco"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="imprimir_quantidade"
                    label-for="imprimir_quantidade"
                    label="Imprimir Quantidade"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setImprimirQuantidade"
                          :checked="imprimir_quantidade"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="considerar_estoque"
                    label-for="considerar_estoque"
                    label="Considerar somente produtos com estoque"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setProdutosEstoqueZerado"
                          :checked="considerar_estoque"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="imprimir_fornecedor"
                    label-for="imprimir_fornecedor"
                    label="Imprimir Fornecedor"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setFornecedorLista"
                          :checked="imprimir_fornecedor"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="imprimir_referencia"
                    label-for="imprimir_referencia"
                    label="Imprimir Referência"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setReferenciaLista"
                          :checked="imprimir_referencia"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="considerar_ativos"
                    label-for="considerar_ativos"
                    label="Considerar somente produtos ativos"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setConsiderarAtivos"
                          :checked="considerar_ativos"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="imprimir_marca"
                    label-for="imprimir_marca"
                    label="Imprimir Marca"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setImprimirMarca"
                          :checked="imprimir_marca"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="cfop_item"
                    label-for="cfop_item"
                    label="CFOP"
                  >
                    <b-form-input
                      id="cfop_item"
                      name="cfop_item"
                      type="text"
                      v-model="cfop_item"
                      maxlength="4"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Escolha a ordenação"
                    label-for="ordenacao"
                  >
                    <select
                      name="ordenacao_lista_preco"
                      id="ordenacao_lista_preco"
                      v-model="ordenacao_lista_preco"
                      class="form-control"
                    >
                      <option
                        v-for="(o, index) in ordenar_lista_preco"
                        :value="o.campo"
                        :key="'campo' + index"
                      >
                        {{ o.descricao }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Tipo do produto"
                    label-for="tipo_produto"
                  >
                    <select
                      name="tipo_produto"
                      id="tipo_produto"
                      v-model="tipo_produto"
                      class="form-control"
                    >
                      <option
                        v-for="(t, index) in tipos"
                        :value="t.id"
                        :key="'campo' + index"
                      >
                        {{ t.descricao }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group label="Grupo" label-for="grupos">
                    <select
                      name="grupos"
                      id="grupos"
                      v-model="grupo_lista_preco"
                      class="form-control"
                    >
                      <option
                        v-for="(g, index) in grupos"
                        :value="g.id"
                        :key="'grupos' + index"
                      >
                        {{ g.nome }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="% para aplicar no preço de venda"
                    label-for="percentual_preco"
                  >
                    <money
                      class="form-control"
                      v-model="percentual_preco"
                      v-bind="configMoney"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarListaDePrecos()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Inventário:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_limite"
                    label-for="data_limite"
                    label="Data Limite"
                  >
                    <b-form-input type="date" v-model="data_limite">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="livro"
                    label-for="livro"
                    label="Número livro"
                  >
                    <b-form-input type="number" v-model="livro"> </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="formato_csv"
                    label-for="formato_csv"
                    label="Gerar em formato CSV"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setFormatoCSV"
                          :checked="formato_csv"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarInventario()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="12">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Gerar etiqueta do produto:</h4>
              <b-row>
                <b-col sm="12">
                  <VueSelect
                    placeholder="Informe a descrição do produto"
                    ref="buscarProduto"
                    label="id"
                    :filterable="false"
                    @input="setProdutoEtiqueta"
                    v-model="valueProdutoEtiqueta"
                    :options="produtos"
                    @search="onSearchProduto"
                    multiple
                    :selectable="() => valueProdutoEtiqueta.length < 20"
                  >
                    <template slot="no-options">
                      Informe a descrição do produto
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.descricao.substring(10, 0) }}
                      </div>
                    </template>
                  </VueSelect>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col sm="2">
                  <b-form-group
                    id="numero_compra"
                    label-for="numero_compra"
                    label="Número da compra"
                  >
                    <b-form-input
                      id="numero_compra"
                      name="numero_compra"
                      type="text"
                      v-model="numero_compra"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="3" v-if="numero_compra">
                  <b-form-group
                    id="somente_um_item"
                    label-for="somente_um_item"
                    label="Somente uma etiqueta por produto"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setUmItem"
                          :checked="somente_um_item"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <label>Selecione o modelo da etiqueta</label>
                  <b-form-select
                    v-model="modeloEtiqueta"
                    @input="setModeloEtiqueta"
                  >
                    <b-form-select-option
                      v-for="m in modelosEtiqueta"
                      :key="m.id + 'etiquetas'"
                      :value="m"
                    >
                      {{ m.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-col>
                <b-col
                  md="3"
                  v-if="modeloEtiqueta && modeloEtiqueta.permite_alteracao"
                >
                  <b-form-group
                    id="linha_inicio"
                    label-for="linha_inicio"
                    label="Linha de Início"
                  >
                    <b-form-input
                      id="linha_inicio"
                      name="linha_inicio"
                      type="text"
                      v-model="linha_inicio"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col
                  md="3"
                  v-if="modeloEtiqueta && modeloEtiqueta.permite_alteracao"
                >
                  <b-form-group
                    id="coluna_inicio"
                    label-for="coluna_inicio"
                    label="Coluna de Início"
                  >
                    <b-form-input
                      id="coluna_inicio"
                      name="coluna_inicio"
                      type="text"
                      v-model="coluna_inicio"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    id="codBarras"
                    label-for="codBarras"
                    label="Gerar código de barra"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setCodBarras"
                          :checked="cod_barras"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="preco"
                    label-for="preco"
                    label="Gerar preço de venda"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setPrecoVenda"
                          :checked="preco"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="imprimir_logo"
                    label-for="imprimir_logo"
                    label="Impressão da logo"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setImpressaoLogo"
                          :checked="imprimir_logo"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="considerar_referencia"
                    label-for="considerar_referencia"
                    label="Considerar referência"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setReferencia"
                          :checked="considerar_referencia"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="considerar_codigo_interno"
                    label-for="considerar_codigo_interno"
                    label="Considerar código interno"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setCodigoInterno"
                          :checked="considerar_codigo_interno"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="4">
                  <b-form-group
                    id="repetir"
                    label-for="repetir"
                    label="Repetir"
                  >
                    <b-form-input
                      id="repetir"
                      name="repetir"
                      type="number"
                      v-model="repetir"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                    id="font_size"
                    label-for="font_size"
                    label="Tamanho da fonte"
                  >
                    <b-form-input
                      min="3"
                      max="10"
                      id="font_size"
                      name="font_size"
                      type="number"
                      v-model="font_size"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    id="texto_personalizado_etiqueta"
                    label-for="texto_personalizado_etiqueta"
                    label="Texto Personalizado"
                  >
                    <b-form-input
                      v-model="texto_personalizado_etiqueta"
                      maxlength="150"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <br />
              <button
                @click="geraEtiqueta()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Totalização do estoque:</h4>
              <b-col sm="6">
                <b-form-group
                  id="considerar_somente_qtd"
                  label-for="considerar_somente_qtd"
                  label="Considerar somente produtos com estoque"
                >
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        :value="true"
                        @change="setQuantidadeTot"
                        :checked="considerar_somente_qtd"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
              <button
                @click="gerarTotalizacaoEstoque()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Fornecedor preferencial</h4>
              <b-row>
                <b-col md="12">
                  <VueSelect
                    placeholder="Informe o nome do fornecedor"
                    ref="buscarFornecedor"
                    label="id_pessoa"
                    :filterable="false"
                    @input="setFornecedorEstoque"
                    v-model="fornecedorByEstoque"
                    :options="fornecedores"
                    @search="onSearchFornecedor"
                  >
                    <template slot="no-options">
                      Informe o nome do fornecedor
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </VueSelect>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    id="considerar_estoque_positivo"
                    label-for="considerar_estoque_positivo"
                    label="Considerar somente produtos com estoque"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setEstoquePositivo"
                          :checked="considerar_estoque_positivo"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarEstoqueByFornecedor()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Previsão de Compra</h4>
              <button
                @click="geararPrevisaoCompra()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Cliente</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Histórico:</h4>
              <b-row>
                <b-col md="12">
                  <VueSelect
                    placeholder="Informe o nome do cliente"
                    ref="buscarCliente"
                    label="id_pessoa"
                    :filterable="false"
                    @input="setCliente"
                    v-model="valueCliente"
                    :options="clientes"
                    @search="onSearchCliente"
                  >
                    <template slot="no-options">
                      Informe o nome do cliente
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }} - {{ option.cnpj_cpf }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }} - {{ option.cnpj_cpf }}
                      </div>
                    </template>
                  </VueSelect>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_historico_inicial"
                    label-for="data_historico_inicial"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_historico_inicial">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_historico_final"
                    label-for="data_historico_final"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_historico_final">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <br />
              <button
                @click="gerarHistoricoCliente()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Cadastro de clientes:</h4>
              <br />
              <b-row>
                <b-col md="8">
                  <b-form-group label="Cidade" label-for="formEndereco.cidade">
                    <VueSelect
                      label="cidade"
                      @input="setCidade"
                      :options="cidades"
                      @search="onSearch"
                    >
                      <template slot="no-options">
                        Informe o nome da cidade
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.cidade + " - " + option.uf }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.cidade + " - " + option.uf }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group
                    id="uf_cliente"
                    label-for="uf_cliente"
                    label="UF"
                  >
                    <b-form-input
                      maxLength="2"
                      id="uf_cliente"
                      name="uf_cliente"
                      type="text"
                      v-model="uf_cliente"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarReportClientes()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Clientes inativos:</h4>
              <br />
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_cliente"
                    label-for="data_inicial_cliente"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_cliente">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_cliente"
                    label-for="data_final_cliente"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_cliente">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group label="Cidade" label-for="cidade_inativos">
                    <VueSelect
                      label="cidade"
                      @input="setCidadeClienteInativos"
                      :options="cidades"
                      @search="onSearch"
                    >
                      <template slot="no-options">
                        Informe o nome da cidade
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.cidade + " - " + option.uf }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.cidade + " - " + option.uf }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarReportClientesInativos()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Aniversariantes:</h4>
              <br />
              <b-row>
                <b-col md="6">
                  <b-form-group label="Escolha o mês" label-for="ordenacao">
                    <select
                      name="mes_aniversariante"
                      id="mes_aniversariante"
                      v-model="mes_aniversariante"
                      class="form-control"
                    >
                      <option
                        v-for="(m, index) in meses"
                        :value="m.valor"
                        :key="'campo' + index"
                      >
                        {{ m.mes }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarReportAniversariante()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Controle de Lotes:</h4>
              <br />
              <button
                @click="gerarControleLote()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import swal from "sweetalert";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";
import { MONEY as settingMoney } from "@/modules/Application";
import { Money } from "v-money";
import loadsh from "loadsh";

export default {
  name: "relatorios",
  mixins: [SistemaMixin, PessoaMixin],
  components: {
    VueSelect,
    Money,
  },
  data() {
    return {
      reportTarge: null,
      valueProduto: null,
      produtos: [],
      clientes: [],
      valueCliente: null,
      data_limite: null,
      livro: null,
      data_inventario: null,
      valueProdutoEtiqueta: [],
      cod_barras: true,
      preco: true,
      repetir: 1,
      modelosEtiqueta: [],
      modeloEtiqueta: {},
      imprimir_custo: true,
      font_size: 3,
      texto_personalizado_etiqueta: null,
      ordenacao_lista_preco: "descricao",
      ordenar_lista_preco: [
        {
          campo: "descricao",
          descricao: "Descrição",
        },
        {
          campo: "codigo",
          descricao: "Código",
        },
      ],
      grupos: [],
      grupo_lista_preco: null,
      imprimir_logo: false,
      considerar_referencia: false,
      fornecedores: [],
      considerar_estoque: false,
      imprimir_grupo: true,
      imprimir_preco: true,
      imprimir_quantidade: false,
      imprimir_fornecedor: false,
      formato_csv: false,
      considerar_ativos: true,
      configMoney: settingMoney,
      percentual_preco: null,
      imprimir_marca: false,
      numero_compra: null,
      data_historico_final: null,
      data_historico_inicial: null,
      uf_cliente: null,
      cidades: [],
      cidade: null,
      considerar_estoque_positivo: true,
      fornecedorByEstoque: null,
      data_inicial_cliente: null,
      data_final_cliente: null,
      cfop_item: null,
      tipos: [],
      tipo_produto: null,
      cidade_inativos: null,
      mes_aniversariante: null,
      meses: [
        {
          mes: "Janeiro",
          valor: "1",
        },
        {
          mes: "Fevereiro",
          valor: "2",
        },
        {
          mes: "Março",
          valor: "3",
        },
        {
          mes: "Abril",
          valor: "4",
        },
        {
          mes: "Maio",
          valor: "5",
        },
        {
          mes: "Junho",
          valor: "6",
        },
        {
          mes: "Julho",
          valor: "7",
        },
        {
          mes: "Agosto",
          valor: "8",
        },
        {
          mes: "Setembro",
          valor: "9",
        },
        {
          mes: "Outubro",
          valor: "10",
        },
        {
          mes: "Novembro",
          valor: "11",
        },
        {
          mes: "Dezembro",
          valor: "12",
        },
      ],
      considerar_somente_qtd: false,
      data_inicial_movimentacao: null,
      data_final_movimentacao: null,
      coluna_inicio: null,
      linha_inicio: null,
    };
  },
  watch: {
    reportTarge(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.Echo.leaveChannel(
          "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
        );
      }
    },
  },
  methods: {
    async getDataInit() {
      const data = await this.$http.post("/relatorios/modelos-etiquetas");

      if (data) {
        this.modelosEtiqueta = data.data;
      }
      this.getGrupos().then((data) => (this.grupos = data));

      const dataTipos = await this.$http.post("/produtos/tipos");

      this.tipos = dataTipos.data;
    },
    gerarReportAniversariante() {
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/aniversariantes", {
            mes_aniversariante: this.mes_aniversariante,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarControleLote() {
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/controle-lotes")
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarReportClientesInativos() {
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/clientes-inativos", {
            data_inicial: this.data_inicial_cliente,
            data_final: this.data_final_cliente,
            cliente: this.cidade_inativos,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    geararPrevisaoCompra() {
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/previsao-compra")
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarTotalizacaoEstoque() {
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/totalizacao-estoque", {
            considerar_somente_qtd: this.considerar_somente_qtd ?? false,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarEstoqueByFornecedor() {
      if (!this.fornecedorByEstoque) {
        this.$toast.warning(
          "Selecione o fornecedor que deseja ver os produtos!"
        );
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/fornecedor-estoque", {
            considerar_estoque_positivo: this.considerar_estoque_positivo,
            fornecedorByEstoque: this.fornecedorByEstoque,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarListaDePrecos() {
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/lista-preco", {
            imprimir_custo: this.imprimir_custo,
            ordenacao_lista_preco: this.ordenacao_lista_preco,
            grupo_lista_preco: this.grupo_lista_preco ?? null,
            considerar_estoque: this.considerar_estoque ?? null,
            imprimir_grupo: this.imprimir_grupo ?? null,
            imprimir_preco: this.imprimir_preco ?? null,
            imprimir_quantidade: this.imprimir_quantidade ?? null,
            imprimir_fornecedor: this.imprimir_fornecedor ?? null,
            considerar_ativos: this.considerar_ativos ?? null,
            percentual_preco: this.percentual_preco ?? null,
            imprimir_referencia: this.imprimir_referencia ?? null,
            imprimir_marca: this.imprimir_marca ?? null,
            cfop_item: this.cfop_item ?? null,
            tipo_produto: this.tipo_produto ?? null,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarMovimentacaoProduto() {
      if (!this.valueProduto) {
        this.$toast.warning(
          "Selecione o produto que deseja ver a movimentação!"
        );
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/movimentacao-produto", {
            id: this.valueProduto.id,
            data_inicial_movimentacao: this.data_inicial_movimentacao,
            data_final_movimentacao: this.data_final_movimentacao,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarHistoricoCliente() {
      if (!this.valueCliente) {
        this.$toast.warning("Selecione o cliente que deseja ver o histórico!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/historico-cliente", {
            id: this.valueCliente.id_pessoa,
            tela: false,
            data_historico_final: this.data_historico_final ?? null,
            data_historico_inicial: this.data_historico_inicial ?? null,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarInventario() {
      if (!this.data_limite) {
        this.$toast.warning("Informe a data limite para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/inventario", {
            data_limite: this.data_limite,
            livro: this.livro,
            formato_csv: this.formato_csv,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            if (response.data) {
              window.open(response.data.url_report, "_blank");
            }
            this.$toast.success("Inventário gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    geraEtiqueta() {
      if (!this.valueProdutoEtiqueta) {
        this.$toast.warning("Selecione um produto para geração de etiquetas!");
        return;
      }
      if (!this.modeloEtiqueta) {
        this.$toast.warning("Selecione um modelo de etiqueta!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização das etiquetas?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/etiqueta", {
            produtos: this.valueProdutoEtiqueta,
            preco: this.preco,
            cod_barras: this.cod_barras,
            repetir: this.repetir,
            modeloEtiqueta: this.modeloEtiqueta.valor,
            font_size: this.font_size,
            texto_personalizado_etiqueta:
              this.texto_personalizado_etiqueta ?? null,
            imprimir_logo: this.imprimir_logo,
            considerar_referencia: this.considerar_referencia ?? null,
            numero_compra: this.numero_compra ?? null,
            considerar_codigo_interno: this.considerar_codigo_interno ?? false,
            somente_um_item: this.somente_um_item ?? false,
            linha_inicio: this.linha_inicio ?? null,
            coluna_inicio: this.coluna_inicio ?? null,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            if (response["data"]["url_report"]) {
              window.open(response["data"]["url_report"], "_blank");
            }
            this.$toast.success("Etiquetas geradas com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    onSearchFornecedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchFornecedor(loading, search, this);
      }
    },
    searchFornecedor: _.debounce(async (loading, search, vm) => {
      vm.fornecedores = await vm.getFornecedorNome(search);
      loading(false);
    }, 350),
    setFornecedorEstoque(fornecedor) {
      this.fornecedorByEstoque = fornecedor;
    },
    setEstoquePositivo() {
      this.considerar_estoque_positivo =
        this.considerar_estoque_positivo == true ? false : true;
    },
    setProdutosEstoqueZerado() {
      this.considerar_estoque = this.considerar_estoque == true ? false : true;
    },
    setReferencia() {
      this.considerar_referencia =
        this.considerar_referencia == true ? false : true;
    },
    setCodigoInterno() {
      this.considerar_codigo_interno =
        this.considerar_codigo_interno == true ? false : true;
      this.considerar_referencia = false;
      this.cod_barras = false;
    },
    setImpressaoLogo() {
      this.imprimir_logo = this.imprimir_logo == true ? false : true;
    },
    setPrecoVenda() {
      this.preco = this.preco == true ? false : true;
    },
    setUmItem() {
      this.somente_um_item = this.somente_um_item == true ? false : true;
    },
    setCodBarras() {
      this.cod_barras = this.cod_barras == true ? false : true;
    },
    setImprimirCusto() {
      this.imprimir_custo = this.imprimir_custo == true ? false : true;
    },
    setImprimirGrupo() {
      this.imprimir_grupo = this.imprimir_grupo == true ? false : true;
    },
    setImprimirPreco() {
      this.imprimir_preco = this.imprimir_preco == true ? false : true;
    },
    setImprimirQuantidade() {
      this.imprimir_quantidade =
        this.imprimir_quantidade == true ? false : true;
    },
    setFornecedorLista() {
      this.imprimir_fornecedor =
        this.imprimir_fornecedor == true ? false : true;
    },
    setReferenciaLista() {
      this.imprimir_referencia =
        this.imprimir_referencia == true ? false : true;
    },
    setProdutoEtiqueta(produto) {
      this.valueProdutoEtiqueta = produto;
    },
    setModeloEtiqueta(etiqueta) {
      this.possivel_editar = etiqueta;
      this.coluna_inicio = null;
      this.linha_inicio = null;
    },
    setProduto(produto) {
      this.valueProduto = produto;
    },
    setCliente(cliente) {
      this.valueCliente = cliente;
    },
    setFormatoCSV(csv) {
      this.formato_csv = csv == true ? false : true;
    },
    setImprimirMarca() {
      this.imprimir_marca = this.imprimir_marca == true ? false : true;
    },
    onSearchProduto(search, loading) {
      if (search.length) {
        loading(true);
        this.searchProduto(loading, search, this);
      }
    },
    setConsiderarAtivos() {
      this.considerar_ativos = this.considerar_ativos == true ? false : true;
    },
    setCidade(cidade) {
      this.cidade = cidade;
      this.uf_cliente = cidade.uf;
    },
    setCidadeClienteInativos(cidade) {
      this.cidade_inativos = cidade;
    },
    setQuantidadeTot() {
      this.considerar_somente_qtd =
        this.considerar_somente_qtd == true ? false : true;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: loadsh.debounce(async (loading, search, vm) => {
      const city = await vm.getCidade(search);
      vm.cidades = city;
      loading(false);
    }, 350),
    gerarReportClientes() {
      swal({
        title: "Deseja realizar a visualização do relatório?",
        text: "Aguarde, pois será notificado no menu quando o relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/clientes", {
            uf_cliente: this.uf_cliente,
            cidade: this.cidade,
          })
          .then(() => {
            swal.stopLoading();
            swal.close();
            this.$toast.success("Solicitação realizada com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    searchProduto: _.debounce(async (loading, search, vm) => {
      vm.produtos = await vm.getProdutoByDescricao(search);
      loading(false);
    }, 350),
    onSearchCliente(search, loading) {
      if (search.length) {
        loading(true);
        this.searchClient(loading, search, this);
      }
    },
    searchClient: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getPessoaNome(search);
      loading(false);
    }, 350),
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    await this.getDataInit();
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
