<template>
  <div class="card-form container" v-if="dataFetched">
    <b-row class="mb-5 w-100 d-flex justify-content-around" :style="cardStyle">
      <img alt="Logo" :src="emissor.logo" class="max-h-80px" />
      <div class="mt-5" style="float: right; text-align: right">
        <span>{{ emissor.nome }} - {{ emissor.telefone }}</span> <br />
        <span v-if="emissor.cnpj_cpf.length < 14"
          >CPF: {{ formatCpfCnpj(emissor.cnpj_cpf) }}</span
        >
        <span v-if="emissor.cnpj_cpf.length >= 14"
          >CNPJ: {{ formatCpfCnpj(emissor.cnpj_cpf) }}</span
        >
        <br />
        <span>{{
          `${emissor.logradouro} - ${emissor.numero} - ${emissor.bairro}, ${emissor.cep}`
        }}</span
        ><br />
        <span>{{ `${emissor.cidade} - ${emissor.uf}` }}</span>
        <br />
      </div>
    </b-row>

    <strong>Orçamento: </strong><span> {{ numero }} </span>
    <div v-if="!finished">
      <div v-if="!isAnswered">
        <b-card
          title="Cliente"
          tag="article"
          class="my-2 border border-primary"
          :style="cardStyle"
        >
          <b-card-text>
            <strong>Nome: </strong>{{ cliente.nome }}<br />
            <strong
              v-if="cliente.cnpj_cpf !== null && cliente.cnpj_cpf.length < 14"
              >CPF: {{ formatCpfCnpj(cliente.cnpj_cpf) }} <br />
            </strong>
            <strong
              v-if="cliente.cnpj_cpf !== null && cliente.cnpj_cpf.length >= 14"
              >CNPJ: {{ formatCpfCnpj(cliente.cnpj_cpf) }}<br
            /></strong>

            <strong v-if="cliente.telefone">Telefone:</strong
            >{{ cliente.telefone }}<br />
            <div style="white-space: normal" v-if="cliente.cep">
              <strong>Endereço: </strong>
              {{ cliente.logradouro }}, {{ cliente.numero }},{{
                cliente.bairro
              }},{{ cliente.complemento }} {{ cliente.cep }},{{
                cliente.cidade
              }}-{{ cliente.uf }}
            </div>
          </b-card-text>
        </b-card>
        <b-card
          title="Produtos"
          tag="article"
          class="mb-2 border border-primary"
        >
          <b-table
            v-if="produtos.length > 0"
            striped
            small
            hover
            :items="tableItems"
          >
            <template #row-details="row">
              <b-row
                v-if="getProdutoObservacao(row)"
                style="white-space: pre-line"
              >
                <b-col>Observacao: {{ getProdutoObservacao(row) }} </b-col>
              </b-row>
            </template>
          </b-table>
        </b-card>
        <b-card
          title="Pagamentos"
          tag="article"
          class="mb-2 border border-primary"
          :style="cardStyle"
        >
          <b-card-text
            v-for="(pagamento, index) in pagamentos"
            :key="pagamento.descricao"
          >
            <label>{{ pagamento.paymentMode.name }}</label>
            <b-table
              v-if="produtos.length > 0"
              striped
              small
              fixed
              hover
              :items="tablePagamentoItems(index)"
            />
          </b-card-text>
        </b-card>

        <b-card
          title="Outras informaçoes"
          tag="article"
          class="my-2 border border-primary"
          :style="cardStyle"
        >
          <b-card-text>
            <strong>Vendedor: </strong> {{ vendedor }} <br />
            <strong>Solicitante: </strong> {{ solicitante }} <br />
            <strong>Observação: </strong> {{ observacao }} <br />
            <strong>Validade: </strong>
            {{ validade | moment("DD/MM/YYYY HH:mm:ss") }}
            <br />
            <strong>Peso Bruto: </strong> {{ numberToReal(pesoBruto, 2) }}
            <br />
            <strong>Peso Líquido: </strong> {{ numberToReal(pesoLiquido, 2) }}
            <br />
            <strong>Desconto Geral R$: </strong>
            {{ numberToReal(desconto, 2) }} <br />
            <strong>Outras despesas R$: </strong>
            {{ numberToReal(despesas, 2) }}
            <br />
            <strong>Total orçamento R$: </strong>
            {{ numberToReal(valorTotal, 2) }}
          </b-card-text>
        </b-card>

        <span
          slot="status"
          v-if="
            status != 1 &&
            status != 7 &&
            status != 2 &&
            status != 6 &&
            status != 9 &&
            status != 10
          "
          :class="statusClass(status)"
        >
          {{ statusLabel(status) }}, por {{ contatoResposta }} :
          {{ dataResposta | moment("DD/MM/YYYY HH:mm:ss") }}
        </span>
        <div
          class="mt-5 w-100 d-flex justify-content-center"
          v-if="status == 1 || status == 7 || status == 2 || status == 6"
        >
          <button
            @click="handleClientResponse(true)"
            class="btn btn-success btn-hover-primary mr-2"
            v-b-tooltip.hover.bottom="'Confirmar Orçamento'"
          >
            Aprovar
          </button>
          <button
            @click="handleClientResponse(false)"
            class="btn btn-danger btn-hover-primary mr-2"
            v-b-tooltip.hover.bottom="'Confirmar Orçamento'"
          >
            Não aprovar
          </button>
        </div>
      </div>
      <div v-else>
        <b-card
          title="Confirmação"
          tag="article"
          class="my-2 border border-primary"
          :style="cardStyle"
        >
          <b-form-group label="Status" label-for="status">
            <VueSelect
              ref="buscarStatus"
              :filterable="false"
              :options="allStatus"
              @search="onSearchStatus"
              v-model="statusClient"
            >
              <template slot="no-options"> Informe o status </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.descricao }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.descricao }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
          <b-form-group label="Nome do responsável" label-for="contato">
            <b-form-input
              id="contato"
              name="contato"
              v-model="contato"
              class="contato-input"
            />
          </b-form-group>
          <b-form-group label="Observações" label-for="observacaoCliente">
            <v-textarea
              full-width
              no-resize
              rows="1"
              auto-grow
              v-model="observacaoCliente"
            ></v-textarea>
          </b-form-group>
          <div class="mt-5 w-100 d-flex justify-content-center">
            <button
              @click="handleSendResponse"
              class="btn btn-success btn-hover-primary mr-2"
              ref="submit"
              v-b-tooltip.hover.bottom="'Confirmar Orçamento'"
            >
              Enviar
            </button>
          </div>
        </b-card>
      </div>
    </div>
    <div class="mt-10 w-100 d-flex justify-content-center" v-else>
      <strong>Obrigado por sua resposta!</strong>
    </div>
    <div class="mt-5 w-100 d-flex justify-content-end">
      <img
        alt="Logo"
        :src="layoutConfig('self.logo.secundary')"
        class="max-h-30px mr-5"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import moment from "moment";
import VueSelect from "vue-select";
import { debounce } from "lodash";

export default {
  name: "app-link-orcamento",
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  directives: {
    mask,
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  components: {
    VueSelect,
  },
  data() {
    return {
      isAnswered: false,
      uuid: null,
      allStatus: [],
      dataFetched: false,
      cliente: null,
      emissor: null,
      validade: null,
      observacao: null,
      pagamentos: [],
      produtos: null,
      status: null,
      valorTotal: null,
      numero: null,
      desonto: null,
      despesas: null,
      statusClient: null,
      observacaoCliente: null,
      contato: null,
      finished: false,
      contatoResposta: null,
      windowWidth: window.innerWidth,
      dataResposta: null,
      solicitante: null,
      vendedor: null,
      pesoBruto: 0,
      pesoLiquido: 0,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
    tableItems() {
      return this.produtos.map((produto) => ({
        codigo: produto.product?.codigo,
        nome: produto.product.descricao,
        quantidade: produto.quantity,
        un_preco: this.numberToReal(produto.price, 2),
        desconto: this.numberToReal(produto.discount, 2),
        total: this.numberToReal(produto.totalPrice, 2),
        _showDetails: true,
      }));
    },
    produtoObservacoes() {
      return this.produtos.map((produto) => produto?.product?.obs);
    },
    isMobile() {
      return this.windowWidth < 569;
    },
    cardStyle() {
      return {
        width: this.isMobile ? "94vw" : "",
      };
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    getProdutoObservacao(row) {
      const observacaoIndex = this.tableItems.findIndex(
        (el) => el.codigo === row.item.codigo
      );
      if (observacaoIndex === -1) return null;
      return this.produtoObservacoes[observacaoIndex];
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async getInit() {
      const { data } = await this.$http.post("/orcamento/lista-status");
      this.allStatus = data;
    },
    tablePagamentoItems(index) {
      return this.pagamentos[index].range.map((parcela, index) => ({
        parcela: index + 1,
        "Valor R$": this.numberToReal(parcela.value, 2),
        vencimento: moment(parcela.date).format("DD/MM/YYYY"),
        // vencimento: moment(pagamento.range[0].date).format("DD/MM/YYYY"),
      }));
    },
    async handleSendResponse() {
      this.addLoadingToButton("submit");
      const { uuid } = this.$route.params;

      if (!this.statusClient) {
        this.$toast.error("Informe o status do orçamento!");
        this.removeLoadingToButton("submit");
        return;
      }

      if (this.statusClient.id === 3) {
        if (!this.contato || this.contato === "") {
          this.$toast.error("Obrigatório informar o nome do responsável");
          this.removeLoadingToButton("submit");
          return;
        }
        await this.$http.post("/orcamento/update-orcamento", {
          uuid,
          status: this.statusClient.id,
          observacaoCliente: this.observacaoCliente ?? "",
          contato: this.contato,
        });
        this.$toast.success("Resposta submetida!");
        this.finished = true;
        this.removeLoadingToButton("submit");
      } else {
        if (!this.contato || this.contato === "") {
          this.$toast.error("Obrigatório informar o nome do responsável");
          this.removeLoadingToButton("submit");
          return;
        }
        if (!this.observacaoCliente || this.observacaoCliente === "") {
          this.$toast.error("Informe o porque no campo observação");
          this.removeLoadingToButton("submit");
          return;
        }
        await this.$http.post("/orcamento/update-orcamento", {
          uuid,
          status: this.statusClient.id,
          observacaoCliente: this.observacaoCliente,
          contato: this.contato,
        });
        this.$toast.success("Resposta submetida!");
        this.finished = true;
        this.removeLoadingToButton("submit");
      }
    },
    async handleSendPayment() {
      this.setLoading(true);

      try {
        this.setLoading(false);
        this.$swal
          .fire({
            icon: "success",
            title: "Obrigado por realizar a compra com a Nuts!",
            confirmButtonText: `Fechar`,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              // this.$router.push({ name: "comprasLista" });
            }
          });
      } catch (error) {
        this.setLoading(false);
        console.log(JSON.stringify(error));
      }
    },

    async handleClientResponse(aproved) {
      this.isAnswered = true;
      this.statusClient = aproved ? this.allStatus[0] : this.allStatus[1];
    },
    onSearchStatus(search, loading) {
      if (search.length) {
        loading(true);
        this.searchStatus(loading, search, this);
      }
    },
    searchStatus: debounce(async (loading, search, vm) => {
      vm.allStatus = await vm.getClientes(search);
      loading(false);
    }, 350),
    statusClass(status) {
      switch (status) {
        case 1:
          return "label label-lg label-inline label-light-warning";
        case 2:
          return "label label-lg label-inline label-light-primary";
        case 3:
          return "label label-lg label-inline label-light-success";
        case 4:
          return "label label-lg label-inline label-light-danger";
        case 5:
          return "label label-lg label-inline label-light-success";
        case 6:
          return "label label-lg label-inline label-light-danger";
        case 7:
          return "label label-lg label-inline label-light-primary";
        case 8:
          return "label label-lg label-inline label-light-success";
        case null:
          return "label label-lg label-inline label-light-primary";
        default:
          return "label label-lg label-inline label-light-warning";
      }
    },
    statusLabel(status) {
      switch (status) {
        case 1:
          return "Editando";
        case 2:
          return "Enviado ao cliente";
        case 3:
          return "Autorizado";
        case 4:
          return "Cancelado";
        case 5:
          return "Finalizado";
        case 6:
          return "Não autorizado";
        case 7:
          return "Visualizado pelo cliente";
        case 8:
          return "Autorizado";
        case null:
          return "Editando";
        default:
          return "";
      }
    },
  },
  async mounted() {
    const { uuid } = this.$route.params;
    this.setLoading(true);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.$http
      .post("/orcamento/busca-orcamento", {
        uuid,
      })
      .then(({ data }) => {
        this.cliente = data.cliente;
        this.emissor = data.emissor;
        this.validade = data.dataValidade;
        this.observacao = data.observacao;
        this.pagamentos = data.pagamentos;
        this.produtos = data.produtos;
        this.status = data.status;
        this.valorTotal = data.valorTotal;
        this.numero = data.numero;
        this.desconto = data.desconto;
        this.despesas = data.outrasDespesas;
        this.solicitante = data.solicitante ?? null;
        if (data.contato) {
          this.contatoResposta = data.contato;
        }
        if (data.dataAlteracao) {
          this.dataResposta = data.dataAlteracao;
        }
        this.vendedor = data.vendedor?.nome ?? null;
        this.pesoLiquido = data.pesoLiquido;
        this.pesoBruto = data.pesoBruto;
        this.dataFetched = true;
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        this.$toast.error(`Erro ao buscar orçamento:\n${error}`);
      });
    await this.getInit();
  },
  created() {},
};
</script>
<style scoped>
svg {
  outline-width: 0;
  outline-color: transparent;
  color: rgb(241, 249, 255);
}

.container {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.select-city .vs__search::placeholder,
.select-city .vs__dropdown-toggle,
.select-city .vs__dropdown-menu {
  height: 10rem !important;
  /* width:  100%; */
  background-color: green !important;
}

.contato-input {
  height: 3rem;
}
</style>