<template>
  <Modal :value="open" :onClose="close" :close="false">
    <h4 class="title">Editando Item</h4>
    <b-row class="form-group">
      <b-col class="busca" md="6">
        <Input
          ref="descricao"
          label="Descrição"
          id="descricao"
          @keypress.enter.prevent="save"
          labelBold
          v-model="valueProduto.descricao"
        />
      </b-col>
      <b-col>
        <b-form-group label="Quantidade" label-for="quantidade">
          <money
            class="form-control"
            id="quantidade"
            name="quantidade"
            v-model="valueProduto.quantity"
            :disabled="valueProduto.lote"
            v-bind="quantityOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Valor Unitário" label-for="preco">
          <money
            class="form-control"
            id="preco"
            name="preco"
            v-model="valueProduto.price"
            v-bind="priceOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Desconto" label-for="desconto">
          <money
            class="form-control"
            id="desconto"
            name="desconto"
            v-model="valueProduto.discount"
            v-bind="configMask"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group label="Total de Item R$" label-for="total">
          <money
            class="form-control"
            id="total"
            name="total"
            disabled
            v-model="totalProduto"
            v-bind="priceOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="confirm-buttons">
      <Button type="error" @click="close()">Fechar</Button>
      <Button
        type="success"
        @click="
          () => {
            save();
            close();
          }
        "
        >Gravar</Button
      >
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@/components";
import { Button, Input } from "@/components/Form";
import { Money } from "v-money";
import {
  MONEY as settingMoney,
  priceOptions,
  quantityOptions,
} from "@/modules/Application";
import { SistemaMixin } from "@/modules/Application";
import FormatMixin from "@/modules/Application/mixins/FormatMixin";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import { debounce } from "loadsh";
import "vue-select/dist/vue-select.css";

export default {
  name: "ModalProduto",
  mixins: [FormatMixin, PessoaMixin, SistemaMixin],
  components: {
    Modal,
    Button,
    Input,
    Money,
  },
  data() {
    return {
      clientes: [],
      priceOptions,
      quantityOptions,
      valueProduto: {},
      configMask: settingMoney,
      cstcsosn: null,
      cstcsosnContribuinte: null,
      cstcsosns: [],
      cfop: null,
      cfops: [],
      ncm: null,
      ncms: [],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
    changeProduto: {
      type: Function,
      required: true,
    },
    produto: {
      type: Object,
      required: true,
    },
  },
  watch: {
    produto(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valueProduto = newValue;
        this.setCFOP(this.produto.cfop);
      }
    },
  },
  mounted() {
    this.valueProduto = this.produto;
    this.cstcsosn = this.valueProduto.cst_csosn;
    this.cstcsosnContribuinte = this.valueProduto.cst_csosn_contribuinte;
    this.cfop = this.valueProduto.cfop;
    this.ncm = this.valueProduto.ncm;
    this.getDataInit();
  },
  methods: {
    save() {
      this.valueProduto = {
        ...this.valueProduto,
        cst_csosn: this.cstcsosn,
        cfop: this.cfop,
        ncm: this.ncm,
        cst_csosn_contribuinte: this.cstcsosnContribuinte,
      };
      this.changeProduto(this.valueProduto);
      this.$toast.success("Produto editado com sucesso!");
    },
    async getDataInit() {
      this.getCstCsosn().then((data) => (this.cstcsosns = data));
    },
    setCSTCSOSNContribuinte(cstcsosn) {
      this.cstcsosnContribuinte = cstcsosn;
    },
    setCSTCSOSN(cstcsosn) {
      this.cstcsosn = cstcsosn;
    },
    setNCM(ncm) {
      this.ncm = ncm;
    },
    setProduto(produto) {
      this.valueProduto = produto;
    },
    setCFOP(cfop) {
      this.cfop = cfop;
    },
    onSearchCFOP(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCFOP(loading, search, this);
      }
    },
    searchCFOP: debounce(async (loading, search, vm) => {
      vm.cfops = await vm.getCFOPByCodigo(search);
      loading(false);
    }, 350),
    onSearchNCM(search, loading) {
      if (search.length) {
        loading(true);
        this.searchNCM(loading, search, this);
      }
    },
    searchNCM: debounce(async (loading, search, vm) => {
      vm.ncms = await vm.getNCM(search);
      loading(false);
    }, 350),
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getPessoaNome(search);
      loading(false);
    }, 350),
  },
  computed: {
    totalProduto() {
      return (
        this.valueProduto.quantity * this.valueProduto.price -
        this.valueProduto.discount
      );
    },
  },
};
</script>
