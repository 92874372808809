<template>
  <b-tab
    title="Outras informações"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab && !store.isUpdate"
    class="w-75"
    @click="() => (store.currentTab = 3)"
  >
    <label>Validade</label>
    <b-row>
      <b-col sm="6">
        <b-form-group id="validadeData" label-for="validadeData" label="Data">
          <b-form-datepicker v-model="form.validade" locale="pt-br" />
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group id="validadeHora" label-for="validadeHora" label="Hora">
          <b-form-timepicker
            @input="setValidadeHour"
            v-model="form.validadeHour"
            locale="pt-br"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Status" label-for="status">
          <VueSelect
            ref="buscarStatus"
            :filterable="false"
            @input="setStatus"
            :options="allStatus"
            v-model="form.status"
          >
            <template slot="no-options"> Informe o status </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Peso Bruto" label-for="pesoBruto">
          <money
            class="form-control"
            id="pesoBruto"
            name="pesoBruto"
            v-model="form.pesoBruto"
            v-bind="quantityOptions"
            @input="pesoEditado = true"
          >
          </money>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Peso Líquido" label-for="pesoLiquido">
          <money
            class="form-control"
            id="pesoLiquido"
            name="pesoLiquido"
            v-model="form.pesoLiquido"
            v-bind="quantityOptions"
            @input="pesoEditado = true"
          >
          </money>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="">
        <b-form-group
          label="Informações Adicionais"
          label-for="informacoesAdicionais"
        >
          <v-textarea
            full-width
            no-resize
            rows="1"
            auto-grow
            v-model="form.observacao"
          ></v-textarea>
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeOrcamento";
import InformacoesData from "../InformacoesData";
import { Money } from "v-money";
import { quantityOptions } from "@/modules/Application";

export default {
  name: "app-orcamento-form-informacoesTab",
  components: {
    VueSelect,
    Money,
  },
  directives: {
    mask,
  },
  data() {
    return {
      form: {},
      allStatus: [],
      status: null,
      keyToReRender: 0,
      store,
      statusError: false,
      enderecoError: false,
      statusFilled: false,
      quantityOptions,
      pesoEditado: false,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form = new InformacoesData({});
      const { data } = await this.$http.post("/orcamento/status");
      this.allStatus = data;
      this.form.status = this.allStatus.find((item) => item.id === 1);
      this.form.validade = this.thisDate();
      this.form.validadeHour = this.thisTime();

      if (Object.keys(this.$route.params).length !== 0) {
        const { dataValidade, observacao, status, pesoLiquido, pesoBruto } =
          this.$route.params.orcamentoData;
        this.form.observacao = observacao;
        this.form.validade = dataValidade;
        this.form.validadeHour = dataValidade.split(" ")[1];
        this.form.status = this.allStatus.find((st) => st.id === status);
        this.form.pesoLiquido = pesoLiquido;
        this.form.pesoBruto = pesoBruto;
      }
    },
    setStatus(status) {
      this.form.status = status;
      this.statusFilled = true;
    },
    setValidadeHour(time) {
      this.form.validade = `${this.form.validade.split(" ")[0]} ${time}`;
      this.form.validadeHour = time;
    },
  },
  created() {
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 3;
    },
    pesoTotal() {
      if (!store.formProduto || !store.formProduto.produtos) return 0;

      return store.formProduto.produtos.reduce(
        (total, produto) =>
          total + (produto.product.peso || 0) * (produto.quantity || 1),
        0
      );
    },
  },
  watch: {
    "store.form"(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.keyToReRender++;
      }
    },
    form(newValue) {
      this.store.formInformacoes = newValue;
    },
    pesoTotal(newValue) {
      if (this.pesoEditado) {
        this.form.pesoLiquido = newValue;
        this.form.pesoBruto = newValue;
      }
    },
  },
};
</script>
