<template>
  <div class="container-fluid">
    <div class="card-body h-100">
      <b-card>
        <b-tabs pills vertical nav-wrapper-class="w-25">
          <b-tab
            title="Informações fiscais"
            :active="store.currentTab === 0"
            :disabled="store.currentTab !== 0"
          >
            <b-row>
              <b-col md="5">
                <b-form-group label="Modelo" label-for="model">
                  <b-form-select v-model="form.modeloNFe" placeholder="">
                    <b-form-select-option
                      v-for="model in models"
                      :key="model.modelo"
                      :value="model.modelo"
                    >
                      {{ model.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group label="Natureza" label-for="natureza">
                  <VueSelect
                    ref="buscarNatureza"
                    :filterable="false"
                    @input="setNatureza"
                    :options="naturezas"
                    :class="{ hasErrors: naturezaError }"
                    @search="onSearchCFOP"
                    v-model="natureza"
                  >
                    <template slot="no-options">
                      Informe o código da Natureza
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="CFOP" label-for="cfop">
                  <b-form-input disabled id="cfop" name="cfop" v-model="cfop">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-form-group
                  id="chaveDeAcesso"
                  label-for="chaveDeAcesso"
                  label="Chave"
                >
                  <b-form-input
                    id="chaveDeAcesso"
                    name="chaveDeAcesso"
                    v-model="form.chaveDeAcesso"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="numeroNFe"
                  label-for="numeroNFe"
                  label="Número"
                >
                  <b-form-input
                    id="numeroNFe"
                    name="numeroNFe"
                    v-model="$v.form.numeroNFe.$model"
                    maxlength="10"
                    :state="validateState('numeroNFe')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="serieNFe" label-for="serieNFe" label="Série">
                  <b-form-input
                    id="serieNFe"
                    name="serieNFe"
                    v-model="$v.form.serieNFe.$model"
                    maxlength="5"
                    :state="validateState('serieNFe')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            title="Fornecedor"
            :active="store.currentTab === 1"
            :disabled="store.currentTab !== 1"
          >
            <b-row>
              <b-col md="6">
                <b-form-group label="Fornecedor" label-for="fornecedor">
                  <VueSelect
                    ref="buscarFornecedor"
                    :filterable="false"
                    v-model="fornecedor"
                    @input="setFornecedor"
                    :options="fornecedores"
                    @search="onSearchFornecedor"
                  >
                    <template slot="no-options">
                      Informe o nome do Fornecedor
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group
                  id="dataEmissao"
                  label-for="dataEmissao"
                  label="Data Emissão"
                >
                  <b-form-datepicker
                    v-model="$v.form.dataEmissao.$model"
                    :state="validateState('dataEmissao')"
                    locale="pt-br"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="dataEntrada"
                  label-for="dataEntrada"
                  label="Data Entrada"
                >
                  <b-form-datepicker
                    v-model="$v.form.dataEntrada.$model"
                    :state="validateState('dataEntrada')"
                    locale="pt-br"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            title="Valores Totais"
            :active="store.currentTab === 2"
            :disabled="store.currentTab !== 2"
          >
            <b-row>
              <b-col md="2">
                <b-form-group
                  id="valor-total"
                  label-for="valor-total"
                  label="Total Nota"
                >
                  <money
                    class="form-control"
                    v-model="formImpostos.totalProdutos"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="base-icms"
                  label-for="base-icms"
                  label="Base de Cálculo"
                >
                  <money
                    class="form-control"
                    v-model="formImpostos.icmsBase"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="valor-icms"
                  label-for="valor-icms"
                  label="Valor ICMS"
                >
                  <money
                    class="form-control"
                    v-model="formImpostos.icmsValue"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="outrasDespesas"
                  label-for="outrasDespesas"
                  label="Outras"
                >
                  <money
                    class="form-control"
                    v-model="formImpostos.outrasDespesas"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            title="Pagamento"
            :active="store.currentTab === 3"
            :disabled="store.currentTab !== 3"
          >
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Forma de Pagamento"
                  label-for="formaPagamento"
                >
                  <VueSelect
                    label="descricao"
                    v-model="pagamento"
                    class="busca-cliente"
                    ref="buscaPagamentos"
                    id="buscaPagamentos"
                    :options="pagamentos"
                    @input="changePagamentos"
                  >
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="numero" label-for="numero" label="Valor R$">
                  <money
                    class="form-control"
                    id="valorPagamento"
                    name="valorPagamento"
                    :value="valorPgto"
                    v-bind="configMask"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Plano de contas" label-for="buscar_planos">
                  <VueSelect
                    @input="setPlanoContas"
                    @search="onSearchPlano"
                    ref="buscar_planos"
                    :filterable="false"
                    placeholder="Seleciona um plano de contas"
                    :options="plano_contas"
                    v-model="planoContasSelecionado"
                  >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="listaPagamentos.length > 0">
                <b-col class="busca">
                  <TableApp
                    item="pagamento"
                    subTitulo=""
                    :data="listaPagamentos[0].range"
                    :fields="fields"
                    classFilterColumn="valor"
                    ref="valor"
                  >
                    <template slot="customRow" scope="props">
                      <span
                        slot="nome"
                        class="pl-0 pt-8"
                        v-if="props.field == 'id'"
                      >
                        <div class="d-flex align-items-center">
                          <div class="font-weight-bold text-muted">
                            {{ props.rowData.originalIndex + 1 }}
                          </div>
                        </div>
                      </span>
                      <span
                        slot="nome"
                        class="pl-0 pt-8"
                        v-if="props.field == 'valor'"
                      >
                        <div class="d-flex align-items-center">
                          <div
                            @click="
                              changePagamento(
                                props.rowData.originalIndex,
                                props.rowData.value
                              )
                            "
                            class="font-weight-bold text-muted cursor-pointer"
                          >
                            {{ numberToReal(props.rowData.value, 2) }}
                          </div>
                        </div>
                      </span>
                      <span
                        slot="quantidade"
                        class="pl-0 pt-8"
                        v-if="props.field == 'vencimento'"
                      >
                        <div class="d-flex align-items-center">
                          <div
                            class="font-weight-bold text-muted cursor-pointer"
                            @click="
                              selectVencimento(
                                props.rowData.originalIndex,
                                props.rowData.date
                              )
                            "
                          >
                            {{ props.rowData.date | moment("DD/MM/YYYY") }}
                          </div>
                        </div>
                      </span>
                    </template>
                  </TableApp>
                </b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <button
                  ref="addPagamento"
                  class="btn btn-secondary font-weight-bold mb-10"
                  @click="adicionarPagamento"
                >
                  + Adicionar Pagamento
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <TableApp
                  class="mt-10"
                  item="pagamento"
                  titulo="Pagamentos"
                  :data="listaPagamentos"
                  :fields="fieldsPagamentosRealizados"
                  classFilterColumn="formaPagamento"
                  ref="formaPagamento"
                >
                  <template slot="customRow" scope="props">
                    <span
                      slot="nome"
                      class="pl-0 pt-8"
                      v-if="props.field == 'forma_pagamento'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold text-muted">
                          {{ props.rowData.descricao }}
                        </div>
                      </div>
                    </span>
                    <span
                      slot="quantidade"
                      class="pl-0 pt-8"
                      v-if="props.field == 'valor'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold text-muted">
                          R$ {{ numberToReal(props.rowData.value, 2) }}
                        </div>
                      </div>
                    </span>
                    <span slot="actions" v-if="props.field == 'actions'">
                      <div
                        class="row-actions"
                        style="text-align: right !important"
                      >
                        <span
                          style="
                            overflow: visible;
                            position: relative;
                            width: 130px;
                          "
                        >
                          <div class="action-buttons">
                            <button
                              @click="
                                removePagamento(
                                  props.rowData,
                                  props.rowData.originalIndex
                                )
                              "
                              class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                            >
                              <TrashCanIcon :size="16" />
                            </button>
                          </div>
                        </span>
                      </div>
                    </span>
                  </template>
                </TableApp>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <div v-if="canSaveCompra">
              <button
                :disabled="store.currentTab === 0"
                class="btn btn-secondary font-weight-bold mr-4"
                @click="handlePrevious"
              >
                Anterior
              </button>
              <button
                type="submit"
                ref="submit_nova_compra"
                class="btn btn-primary font-weight-bold"
                @click="salvarCompra"
              >
                Salvar
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'comprasLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
            <div v-else>
              <button
                :disabled="store.currentTab === 0"
                class="btn btn-secondary font-weight-bold mr-4"
                @click="handlePrevious"
              >
                Anterior
              </button>
              <button
                class="btn btn-primary font-weight-bold"
                @click="handleNext"
                :disabled="store.currentTab === 7"
              >
                Proximo
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'comprasLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-calendar" title="Ajustar vencimento" size="sm" centered>
      <div class="mx-auto">
        <b-calendar v-model="vencimentoSelected" locale="pt-BR"></b-calendar>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" @click="salvarVencimento">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-pagamento" title="Ajustar pagamento" size="sm" centered>
      <div class="mx-auto">
        <b-form class="form" @submit.stop.prevent="salvarPagamento">
          <money
            @input="changePagamentoEdited"
            class="form-control"
            id="valorPagamento"
            name="valorPagamento"
            :value="pagamentoSelected"
            v-bind="configMoney"
          >
          </money>
        </b-form>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" @click="salvarPagamento"> Salvar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import FormatMixin from "../../Application/mixins/FormatMixin";
import {
  SistemaMixin,
  ValidacaoMixin,
  InstallmentsMixin,
} from "@/modules/Application";
import OthersData, { OthersDataRequired } from "./OthersData";
import ImpostosData from "./ImpostosData";
import FornecedorData from "./FornecedorData";
import TransportadorData from "./TransportadorData";
import TableApp from "../../../components/Table/Table3.vue";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import PagamentosMixin from "../../Pdv/mixins/PagamentosMixin";
import { store } from "../storeCompra";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import moment from "moment";

export default {
  name: "app-compra-form",
  props: {
    url: {
      type: String,
    },
  },
  directives: {
    mask,
  },
  components: {
    VueSelect,
    Money,
    TableApp,
    TrashCanIcon,
  },
  mixins: [
    validationMixin,
    FormatMixin,
    SistemaMixin,
    ValidacaoMixin,
    PagamentosMixin,
    InstallmentsMixin,
  ],
  data() {
    return {
      configMask: settingMoney,
      store,
      form: {},
      idMeioPagamentoSelected: null,
      formas_pagamento: [],
      pagamentoPreview: [],
      idPlanoContas: null,
      models: [
        {
          descricao: "Nota Fiscal de Serviço de Telecomunicações",
          modelo: 22,
        },
        {
          descricao: "Conta de Energia Elétrica",
          modelo: 6,
        },
        {
          descricao: "Conhecimento de Transporte Eletrônico",
          modelo: 57,
        },
        {
          descricao: "Nota Fiscal de Serviço",
          modelo: 1,
        },
      ],
      vencimentoSelected: null,
      indexVencimento: null,
      selectedPagamento: null,
      naturezaError: false,
      formFornecedor: {},
      formImpostos: {},
      pagamentoSelected: null,
      formTransportador: {},
      formPagamento: {},
      naturezas: [],
      natureza: null,
      cfop: null,
      fornecedores: [],
      fornecedor: null,
      transportadores: [],
      transportador: {},
      hasXmlErrors: false,
      inputFilled: false,
      veiculo: {},
      configMoney: settingMoney,
      productOfTradeCollection: [],
      produtos: [],
      produto: null,
      xmlFile: null,
      valor: "0",
      valorProdutos: "0",
      tiposFrete: [],
      pagamentos: [],
      valorPgto: 0,
      valorPagoCompra: 0,
      listaPagamentos: [],
      plano_contas: [],
      multiplicar: 1,
      divisao: 2,
      fields: [
        {
          label: "",
          field: "id",
          slot: true,
          width: "10%",
          sortable: false,
        },
        {
          label: "Valor R$",
          field: "valor",
          slot: true,
          width: "45%",
          sortable: false,
        },
        {
          label: "Vencimento",
          field: "vencimento",
          slot: true,
          sortable: false,
          width: "45%",
        },
      ],
      fieldsPagamentosRealizados: [
        {
          label: "",
          field: "forma_pagamento",
          slot: true,
          width: "45%",
          sortable: false,
        },
        {
          label: "",
          field: "valor",
          slot: true,
          width: "45%",
          sortable: false,
        },
        {
          label: "",
          field: "actions",
          slot: true,
          width: "10%",
          sortable: false,
        },
      ],
      valorFilled: false,
      planoContasSelecionado: null,
      pagamentosEditados: [],
      formaPagamento: {},
      pagamento: {},
    };
  },
  validations: {
    form: OthersDataRequired,
  },
  watch: {
    valor(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.valorPgto === "R$ 0,00") {
          this.valorPgto = this.numberToReal(newValue, 2);
        }
      }
    },
    totalProdutos(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorProdutos = newValue;
      }
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (oldValue === 0 && newValue === 1) {
          if (!this.form.modeloNFe) {
            this.$toast.error("Selecione um modelo para prosseguir!");
            this.store.currentTab = oldValue;
          }
          if (_.isEmpty(this.form.natureza)) {
            this.$toast.error("Selecione uma natureza para prosseguir!");
            this.naturezaError = true;
            this.store.currentTab = oldValue;
          }
          if (!this.form.numeroNFe || !this.form.serieNFe) {
            this.$toast.error("Preencha os campos obrigatórios!");
            this.store.currentTab = oldValue;
          }
        }
        if (oldValue === 1 && newValue === 2) {
          if (!this.formFornecedor.id_pessoa) {
            this.$toast.error("Selecione o fornecedor!");
            this.store.currentTab = oldValue;
          }
          if (!this.form.dataEmissao || !this.form.dataEntrada) {
            this.$toast.error("Preencha os campos obrigatórios!");
            this.store.currentTab = oldValue;
          }
        }
      }
    },
    formImpostos: {
      handler(value) {
        this.formImpostos.valor = value.totalProdutos + value.outrasDespesas;
        this.valorPgto = this.valorReal(this.formImpostos.valor);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }

    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    this.getDataInit();
  },
  async created() {
    this.store.currentTab = 0;
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form = new OthersData({});
      this.formImpostos = new ImpostosData({});
      this.formFornecedor = new FornecedorData({});
      this.formTransportador = new TransportadorData({});
      this.tiposFrete = await this.getTiposFrete();
      this.getPagamentos();
      this.formas_pagamento = await this.getFormasPagamentos();
      this.plano_contas = await this.getPlanoContas(1);
    },
    changePagamentos(forma_pagamento) {
      this.pagamento = forma_pagamento;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async salvarPagamento() {
      this.listaPagamentos[0].range[this.selectedPagamento].value =
        this.pagamentoEditado;

      const indexEditPagamento = this.pagamentosEditados.findIndex(
        (pagamento) => pagamento?.index === this.selectedPagamento
      );
      if (indexEditPagamento === -1) {
        this.pagamentosEditados.push({
          index: this.selectedPagamento,
          value: this.pagamentoEditado,
        });
      } else {
        this.pagamentosEditados.splice(indexEditPagamento, 1, {
          index: this.selectedPagamento,
          value: this.pagamentoEditado,
        });
      }
      const pagamentosEditadosTotal = this.pagamentosEditados.reduce(
        (acc, pagamento) => {
          if (pagamento?.value) return acc + pagamento.value;
          else return acc;
        },
        0
      );
      const editPagamentoCount = this.pagamentosEditados.length;

      const pagamentoRestante =
        this.listaPagamentos[0].value - pagamentosEditadosTotal;

      const valorParcelasRestantes =
        Math.round(
          (pagamentoRestante /
            (this.listaPagamentos[0].range.length - editPagamentoCount)) *
            100
        ) / 100;
      this.listaPagamentos[0].range = this.listaPagamentos[0].range.map(
        (item, index) => {
          const hasInPagamentosEditado = this.pagamentosEditados.find(
            (pagamento) => pagamento.index === index
          );
          return {
            ...item,
            value: hasInPagamentosEditado
              ? hasInPagamentosEditado.value
              : index !== this.selectedPagamento
              ? valorParcelasRestantes
              : this.pagamentoEditado,
          };
        }
      );

      this.$bvModal.hide("modal-pagamento");
    },
    selectVencimento(index, date) {
      this.$bvModal.show("modal-calendar");
      this.indexVencimento = index;
      this.vencimentoSelected = date;
    },
    changePagamento(pagamentoIndex, value) {
      this.$bvModal.show("modal-pagamento");
      this.selectedPagamento = pagamentoIndex;
      this.pagamentoSelected = value;
    },
    changePagamentoEdited(valor) {
      this.pagamentoEditado = valor;
    },
    salvarVencimento() {
      this.listaPagamentos[0].range[this.indexVencimento].date =
        this.vencimentoSelected;
      this.$bvModal.hide("modal-calendar");
    },

    handleNext() {
      if (this.store.currentTab === 7) return;
      this.store.currentTab++;
    },
    handlePrevious() {
      if (this.store.currentTab === 0) return;
      this.store.currentTab--;
    },
    onSearchCFOP(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCFOP(loading, search, this);
      }
    },
    searchCFOP: _.debounce(async (loading, search, vm) => {
      vm.naturezas = await vm.getNaturezaByDescricao(search);
      loading(false);
    }, 350),

    setNatureza(natureza) {
      this.form.natureza = natureza;
      this.cfop = natureza.cfop.codigo;
      this.naturezaError = false;
    },

    onSearchFornecedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchFornecedor(loading, search, this);
      }
    },

    searchFornecedor: _.debounce(async (loading, search, vm) => {
      vm.fornecedores = await vm.getFornecedorByNome(search);
      loading(false);
    }, 350),

    setFornecedor(fornecedor) {
      this.formFornecedor = fornecedor;
      this.formFornecedor.cep = fornecedor.enderecos[0].cep ?? null;
      this.formFornecedor.logradouro =
        fornecedor.enderecos[0].logradouro ?? null;
      this.formFornecedor.numero = fornecedor.enderecos[0].numero ?? null;
      this.formFornecedor.complemento =
        fornecedor.enderecos[0].complemento ?? null;
      this.formFornecedor.bairro = fornecedor.enderecos[0].bairro ?? null;
      this.formFornecedor.uf = fornecedor.enderecos[0].cidade.uf ?? null;
      this.formFornecedor.cidade =
        fornecedor.enderecos[0].cidade.cidade ?? null;
      this.formFornecedor.fone = fornecedor.fones[0].fone ?? null;
    },

    onSearchPlano(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPlano(loading, search, this);
      }
    },
    searchPlano: _.debounce(async (loading, search, vm) => {
      vm.plano_contas = await vm.getPlanoContasContaPagar(search);
      loading(false);
    }, 450),

    setPlanoContas(planoContas) {
      this.planoContasSelecionado = planoContas;
      this.form.id_plano_contas = planoContas.id;
      this.form.plano_contas = planoContas;
    },

    async salvarCompra() {
      this.addLoadingToButton("submit_nova_compra");
      try {
        this.form.impostos = this.formImpostos;
        this.form.fornecedor = this.fornecedor;
        this.form.pagamentos = this.listaPagamentos;
        await this.$http.post("/compras/create", this.form);
        this.$toast.success("Criado com sucesso!");
        this.$router.push({ name: "comprasLista" });
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("submit_nova_compra");
    },

    async adicionarPagamento() {
      const valor = await this.valorUS(this.valorPgto);

      let pagamento = {
        value: valor,
        ...this.pagamento,
      };

      if (
        (valor > 0 && pagamento.descricao) ||
        pagamento.descricao === "Nenhum"
      ) {
        const range =
          pagamento.descricao !== "Nenhum"
            ? this.calculateInstallments(pagamento, parseFloat(pagamento.value))
            : [
                {
                  date: "-",
                  value: 0,
                },
              ];

        pagamento = {
          ...pagamento,
          range,
        };
        this.checkValorPago(pagamento.value);
        this.listaPagamentos.push(pagamento);

        this.changePagamentos({});

        this.$toast.success("Adicionado com sucesso!");
      }
    },
    checkValorPago(valor = 0) {
      if (valor === 0) {
        this.valorPgto = this.valorReal(
          this.formImpostos.totalProdutos + this.formImpostos.outrasDespesas
        );
        return;
      }

      const valorPagar =
        this.formImpostos.totalProdutos + this.formImpostos.outrasDespesas;
      this.valorPgto = valorPagar < 0 ? "0,00" : this.valorReal(valorPagar);
    },
    async removePagamento(indice) {
      this.listaPagamentos.splice(indice, 1);
      this.valorPgto = this.listaPagamentos.reduce(
        (acc, pagamento) => pagamento.value + acc,
        0
      );
      this.checkValorPago();
      this.$toast.success("Removido com sucesso!");
      this.$nextTick(() => {
        this.$refs.buscaPagamentos.$el.querySelector("input").focus();
      });
    },
  },
  computed: {
    canSaveCompra() {
      return this.store.currentTab === 3;
    },
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },
    toolbarDisplay() {
      return true;
    },
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    },
  },
};
</script>

<style>
.hasErrors .vs__dropdown-toggle {
  border: 2px solid red;
}

.hasErrors .vs__clear,
.hasErrors .vs__open-indicator {
  fill: red;
}

.inputFilled .vs__dropdown-toggle {
  border: 2px solid #1bc5bd;
  /* color: #394066; */
}

.inputFilled .vs__clear,
.inputFilled .vs__open-indicator {
  fill: #1bc5bd;
}

.xmlError .custom-file-input {
  border: 2px solid red;
}

.busca-produto .vs__search {
  font-weight: 400;
  font-size: 1.45rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
  text-transform: uppercase !important;
}

.busca-produto .vs__dropdown-menu {
  width: 550px;
}

.busca-produto .vs__dropdown-toggle {
  border: 0px !important;
}

.busca-produto .vs__actions {
  display: none;
}

.busca-produto .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-produto .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.busca-cliente .vs__search {
  font-size: 18px;
}

.busca-cliente .vs__dropdown-toggle {
  color: var(--primary-color);
  background-color: #fff;
  border: 2px solid #dedede;
  height: 40px;
}

.busca-cliente .vs--open .vs__dropdown-toggle {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}

.busca-cliente .vs__actions {
  display: none;
}

.busca-cliente .vs__dropdown-option--highlight,
.vs__dropdown-menu li:hover,
.vs__dropdown-menu li:focus {
  background-color: var(--primary-color) 5e !important;
  color: var(--primary-color) !important;
}

.busca-cliente .vs__dropdown-menu {
  color: var(--primary-color) !important;
}

.busca-cliente .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-cliente .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-pgto {
  font-weight: bold;
  font-size: 18px;
  color: #4f4f4f;
}

.add-pgto {
  font-weight: bold;
  font-size: 22px;
  color: var(--primary-color);
  float: right;
}

.input-quantidade {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  background: transparent;
  border: transparent;
  color: #fff;
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 0px;
  text-align: right;
}

.input-quantidade:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  border: 0px;
  background-color: transparent;
  color: #fff;
}

.card {
  border: 0;
}

h4 {
  color: #6d747c;
  /* font-weight: 900; */
}

.btn-finalizar-venda {
  background-color: #eee5ff !important;
  font-size: 1.25rem !important;
  text-align: center !important;
  cursor: pointer;
}

.card-total-right p,
.card-total-left p {
  color: #fff !important;
}

.card-header h5,
.card-header h3 {
  margin-bottom: 0rem;
}

.card-header .form-control {
  height: auto;
  margin-top: 0px;
}

.card-header .busca-produto {
  margin-top: 2px;
}

.card-titulo-itens .card-body {
  padding: 5px 0 !important;
}

.card-itens .table tr th {
  padding: 5px;
  font-size: 12px;
}

.card-itens .table tr td,
.card-total-right p,
.card-total-left p {
  font-size: 12px;
}

.tableItensCompra {
  max-height: 300px !important;
  height: calc(100vh - 395px) !important;
}

.card-total-left,
.card-total-right {
  height: 80px !important;
}

.btn.btn-secondary {
  color: var(--primary-color);
  background-color: #fff;
  border-color: #fff;
}
</style>
