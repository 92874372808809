<template>
  <TableApp
    titulo="Cadastro de Unidade de Medida"
    acaoBtn="/#/unidades/nova"
    textoBtn="Novo"
    api-url="/produtos/lista-un"
    :fields="fields"
    classFilterColumn="un"
    ref="un"
    :perPage="10"
  >
    <template slot="customRow" scope="props">
      <span slot="actions" v-if="props.field == 'actions'">
        <div class="row-actions" style="text-align: right !important">
          <span style="overflow: visible; position: relative; width: 110px">
            <div class="dropdown dropdown-inline">
              <router-link
                class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                v-b-tooltip.hover.bottom="'Editar'"
                :to="{
                  name: 'unidadesEditar',
                  params: {
                    id: props.rowData.id,
                  },
                }"
              >
                <Pencil :size="16" title="Editar" />
              </router-link>
              <button
                @click="excluir(props.rowData)"
                class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                v-b-tooltip.hover.bottom="'Excluir'"
              >
                <TrashCanIcon :size="16" />
              </button>
            </div>
          </span>
        </div>
      </span>
    </template>
  </TableApp>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { onboardStore } from "../../../onboardStore";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-un",
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
  },
  mixins: [SistemaMixin],
  data() {
    return {
      onboardStore,
      fields: [
        {
          label: "Descrição",
          field: "nome",
          type: "text",
          sortable: false,
          width: "60%",
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar Descrição",
          },
        },
        {
          label: "Sigla",
          field: "sigla",
          type: "text",
          sortable: false,
          width: "20%",
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar Sigla",
          },
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir a unidade de media: " + data.nome + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/produtos/delete-unidades-medidas", {
                id: data.id,
              });
              this.$refs.un.refresh();
              this.$toast.success("Unidade de medida excluída!");
            }
          });
      })
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
