<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Novo Parcelamento
        </span>
      </h3>
    </div>
    <Form url="/financeiro/create-parcelamento" :parcelamento="parcelamento" />
  </div>
</template>

<script>
import Form from "./Form.vue";
import ParcelamentoData from "./ParcelamentoData";
import { mapGetters } from "vuex";
import { onboardStore } from "../../../onboardStore";
import {
  SistemaMixin,
} from "@/modules/Application";

export default {
  name: "app-formaPagamento-create",
  data() {
    return {
      onboardStore,
      parcelamento: new ParcelamentoData({}),
    };
  },
  components: {
    Form,
  },
  mixins: [SistemaMixin],
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>./ParcelamentoData
