<template>
  <b-modal
    v-model="modalVisible"
    hide-footer
    hide-header
    :dialog-class="'fullscreen-bottom-modal-dialog'"
    :content-class="'fullscreen-bottom-modal-content'"
    size="xl"
  >
    <!-- Tela de boas-vindas -->
    <div v-if="step === 0">
      <h5 class="mb-3">🎉 Seja bem-vindo!</h5>
      <p>
        Vamos te ajudar a deixar o sistema prontinho para o uso em poucos
        minutos.
      </p>

      <p class="mt-3 mb-2 font-weight-bold">O que você vai configurar:</p>
      <ul class="list-unstyled">
        <li class="mb-2">
          <b-icon icon="chevron-right" variant="primary" class="mr-2" />
          Dados do emitente
        </li>
        <li class="mb-2" v-show="podeConfigurarServicos">
          <b-icon icon="chevron-right" variant="primary" class="mr-2" />
          Configuração de serviços
        </li>
        <li class="mb-2" v-show="podeConfigurarServicos">
          <b-icon icon="chevron-right" variant="primary" class="mr-2" />
          Alíquota de ISS (caso emita NFS-e)
        </li>
        <li class="mb-2">
          <b-icon icon="chevron-right" variant="primary" class="mr-2" />
          Finalização com acesso ao sistema
        </li>
      </ul>

      <div class="text-right mt-4">
        <b-button variant="outline-secondary" @click="recusar"
          >Fazer isso depois</b-button
        >
        <b-button variant="primary" class="ml-2" @click="step = 1"
          >Sim, começar</b-button
        >
      </div>
    </div>

    <!-- Etapa 1: Emitente -->
    <div v-if="step === 1">
      <h5 class="mb-3">Primeiro passo: Configurando dados do Emitente</h5>
      <EmitenteForm :url="'/empresa/update'" @formSubmetido="proximaEtapa" />
    </div>

    <!-- Etapa 2: Categorias e Serviços -->
    <div v-if="step === 2 && podeConfigurarServicos">
      <h5 class="mb-4">
        Segundo passo: Você pretende emitir Nota Fiscal de Serviço (NFS-e)?
      </h5>
      <b-form-group>
        <b-form-checkbox
          switch
          v-model="emitirNfse"
          @change="handleToggleNfse"
          style="font-size: 20px"
        >
          {{ emitirNfse ? "Sim" : "Não" }}
        </b-form-checkbox>
      </b-form-group>

      <div v-if="emitirNfse">
        <h5 class="mt-4 mb-4">Configuração de Serviços</h5>
        <ConfiguracaoForm
          :url="'/empresa/create'"
          @configuracaoNFSe="proximaEtapa"
        />
      </div>
    </div>

    <!-- Etapa 3: Alíquota ISS -->
    <div v-if="step === 3 && podeConfigurarServicos">
      <h5 class="mb-4">
        Terceiro passo: Defina a descrição padrão do seru serviço e sua alíquota
        de ISS
      </h5>
      <b-alert show variant="info" class="mb-4">
        Essas configurações não são definitivas, você poderá alterá-las a
        qualquer momento no menu <strong>Configurações/Serviços</strong> do
        sistema.
      </b-alert>
      <div class="d-flex align-items-start mt-3">
        <b-col md="6">
          <b-form-group
            id="discriminacao"
            label-for="discriminacao"
            label="Descrição do serviço"
          >
            <b-form-input
              v-model="discriminacaoServico"
              autofocus
              maxlength="50"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group id="iss" label-for="iss" label="% Aliq. ISS">
            <money
              class="form-control"
              v-bind="configTaxa"
              v-model="aliquotaISS"
            />
          </b-form-group>
        </b-col>

        <b-icon
          icon="info-circle"
          variant="primary"
          v-b-tooltip.hover.right="
            'Caso não souber, verifique com a sua contabilidade'
          "
        ></b-icon>
      </div>
      <b-button
        :disabled="loadingISS"
        variant="primary"
        class="mt-4"
        @click="createTributacaoServico"
      >
        <b-spinner
          small
          label="Salvando..."
          v-if="loadingISS"
          class="mr-2"
        ></b-spinner>
        {{ loadingISS ? "Salvando..." : "Salvar" }}
      </b-button>
    </div>

    <!-- Etapa 4: Resumo final -->
    <div v-if="step === 4">
      <h5 class="mb-4">Tudo pronto! 🚀</h5>

      <ul class="list-unstyled">
        <li class="mb-3">
          <b-icon icon="check-circle" variant="success" class="mr-2" />
          Emitente configurado com sucesso.
        </li>

        <li class="mb-3" v-if="emitirNfse">
          <b-icon icon="check-circle" variant="success" class="mr-2" />
          Serviços e alíquota de ISS configurados.
        </li>

        <li class="mb-3" v-else v-show="podeConfigurarServicos">
          <b-icon icon="check-circle" variant="success" class="mr-2" />
          Configuração concluída sem emissão de NFS-e.
        </li>
      </ul>

      <p class="mt-4">
        Se tiver qualquer dúvida, acesse a nosssa
        <b-link href="https://help.nuts.app.br/" target="_blank">
          Central de Ajuda </b-link
        >.
      </p>
      <div class="text-right mt-4">
        <b-button variant="primary" @click="finalizar"
          >Começar a usar o sistema</b-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
import EmitenteForm from "@/modules/Emitente/pages/Form.vue";
import ConfiguracaoForm from "@/modules/Servicos/pages/Configuracoes.vue";
import { Money } from "v-money";
import { mapGetters } from "vuex";

export default {
  props: ["show"],
  components: {
    EmitenteForm,
    ConfiguracaoForm,
    Money,
  },
  data() {
    return {
      modalVisible: false,
      step: 0,
      totalSteps: 4,
      emitirNfse: false,
      aliquotaISS: "",
      discriminacaoServico: "",
      loadingISS: false,
      configTaxa: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        precision: 2,
      },
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        this.modalVisible = val;
        if (val) this.step = 0;
      },
    },
  },
  computed: {
    ...mapGetters(["licenca"]),
    podeConfigurarServicos() {
      return Number(this.licenca?.id_plan) === 4;
    },
  },
  methods: {
    handleToggleNfse() {
      if (!this.emitirNfse) {
        this.proximaEtapa();
      }
    },
    proximaEtapa() {
      // Se for no passo 1 e o plano não permite serviços, pula direto para o 3
      if (this.step === 1 && !this.podeConfigurarServicos) {
        this.step = 4;
        return;
      }

      if (this.step < this.totalSteps) {
        this.step++;
      } else {
        this.finalizar();
      }
    },
    voltarEtapa() {
      if (this.step > 1) {
        this.step--;
      } else {
        this.step = 0;
      }
    },
    async createTributacaoServico() {
      if (!this.aliquotaISS) {
        this.$toast.warning("Informe a alíquota de ISS!");
      }

      this.loadingISS = true;

      try {
        await this.$http.post("/tributacaoservico/update-tributacao", {
          aliquota_iss: this.aliquotaISS,
          discriminacao: this.discriminacaoServico,
        });
        this.$toast.success("Configuração atualizada com sucesso!");
        this.proximaEtapa();
      } catch (error) {
        console.log(error);
      }
    },
    finalizar() {
      this.$emit("recusou");
      this.modalVisible = false;
    },
    recusar() {
      this.$emit("recusou");
      this.modalVisible = false;
    },
  },
};
</script>

<style scoped>
.fullscreen-bottom-modal-dialog {
  margin: 0;
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  max-width: 100%;
  height: 100vh;
  transform: translateY(0%) !important;
  display: flex;
  align-items: flex-end;
}

.fullscreen-bottom-modal-content {
  width: 100%;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0;
  height: 100vh;
}
</style>
