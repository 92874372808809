<template>
  <div style="position: relative">
    <div style="position: relative">
      <b-sidebar
        id="sidebar-tranferencia-fields"
        backdrop-variant="dark"
        backdrop
        right
        shadow
      >
        <div class="px-2 py-2">
          <div style="width: 100%" class="p-2">
            <h4 class="text-dark">Transferência entre contas</h4>
            <p>A transferência será realizada somente utilizando o saldo.</p>
            <b-col md="12">
              <b-form-group id="banco" label-for="banco" label="Conta Origem:">
                <strong>Movimento do Caixa</strong>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group id="banco" label-for="banco" label="Conta Destino:">
                <b-form-select v-model="transferencia.banco">
                  <b-form-select-option
                    v-for="b in bancos"
                    :key="b.id_banco + 'bancos'"
                    :value="b"
                  >
                    {{ b.nome }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                id="data_transferencia"
                label-for="data_transferencia"
                label="Data Transferência:"
              >
                <b-form-input
                  type="date"
                  id="data_transferencia"
                  placeholder=" "
                  locale="pt-br"
                  v-model="transferencia.data_transferencia"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group id="valor" label-for="valor" label="Valor R$:">
                <money
                  class="form-control"
                  v-model="transferencia.valor_tranferencia"
                  v-bind="configMoney"
                >
                </money>
              </b-form-group>
            </b-col>
          </div>
        </div>
        <template #footer="{ hide }">
          <div class="d-flex text-light align-items-center px-3 py-2">
            <button
              style="width: 100%"
              ref="salvarTranferencia"
              class="btn btn-primary btn-hover-dark font-weight-bold"
              @click="salvarTransferencia"
            >
              Realizar transferência
            </button>
          </div>
        </template>
      </b-sidebar>
      <FilterFields
        :fields="[
          {
            type: 'text',
            label: 'Descrição',
            field: 'descricao',
          },
          {
            type: 'select',
            label: 'Tipo',
            field: 'tipo',
            options: ['Receita', 'Despesa', 'Todos'],
          },
          {
            type: 'date',
            label: 'Data(s) de movimento(s)',
            field: 'data',
          },
          {
            type: 'text',
            label: 'Plano de Contas',
            field: 'plano_contas',
          },
        ]"
      />
      <div class="d-flex align-items-center my-2 display: flex;">
        <b-dropdown
          :text="filtersStore.filterOptionSelected.label"
          split
          split-variant="secondary"
          id="menu"
          variant="primary"
          v-b-tooltip.right="''"
        >
          <b-dropdown-item
            :active="filtersStore.filterOptionSelected.id === 1"
            @click="
              () =>
                (filtersStore.filterOptionSelected = { id: 1, label: 'Hoje' })
            "
            >Hoje</b-dropdown-item
          >
          <b-dropdown-item
            :active="filtersStore.filterOptionSelected.id === 2"
            @click="
              () =>
                (filtersStore.filterOptionSelected = { id: 2, label: 'Ontem' })
            "
          >
            Ontem
          </b-dropdown-item>
          <b-dropdown-item
            :active="filtersStore.filterOptionSelected.id === 3"
            @click="
              () =>
                (filtersStore.filterOptionSelected = {
                  id: 3,
                  label: 'Essa semana',
                })
            "
          >
            Essa semana
          </b-dropdown-item>
          <b-dropdown-item
            :active="filtersStore.filterOptionSelected.id === 4"
            @click="
              () =>
                (filtersStore.filterOptionSelected = {
                  id: 4,
                  label: 'Semana passada',
                })
            "
          >
            Semana passada
          </b-dropdown-item>
          <b-dropdown-item
            :active="filtersStore.filterOptionSelected.id === 5"
            @click="
              () =>
                (filtersStore.filterOptionSelected = {
                  id: 5,
                  label: 'Esse mês',
                })
            "
          >
            Esse mês
          </b-dropdown-item>
        </b-dropdown>
        <b-col>
          <Chart endpoint="/financeiro/total" modulo="financeiro" />
        </b-col>
      </div>
      <b-button
        class="border border-muted"
        v-b-toggle.sidebar-tranferencia-fields
        variant="secondary"
        v-b-tooltip.hover.right="'Tranferência entre contas'"
        style="position: absolute; right: 0; top: 0; z-index: 99"
      >
        <Transfer :size="20" />
      </b-button>
    </div>
    <TableApp
      titulo="Movimento do Caixa"
      acaoBtn="/#/financeiro/novo"
      textoBtn="Novo Movimento"
      api-url="/financeiro"
      :fields="fields"
      classFilterColumn="descricao"
      ref="financeiro"
      :perPage="10"
    >
      <template slot="customRow" scope="props">
        <span slot="data" v-if="props.field == 'data'">
          <div class="font-weight-bold text-muted">
            {{ formatDate(props.rowData.data) }}
          </div>
        </span>
        <span slot="descricao" v-if="props.field == 'descricao'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.descricao }}</a
                >
                <span class="text-muted font-weight-bold d-block">{{
                  props.rowData.plano_conta
                }}</span>
              </div>
            </div>
          </span>
        </span>
        <span slot="valor" v-if="props.field == 'valor'">
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
            >R$
            {{
              props.rowData.tipo == "E"
                ? numberToReal(props.rowData.valor, 2)
                : "-" + numberToReal(props.rowData.valor, 2)
            }}
          </span>
          <span
            :class="
              props.rowData.tipo == 'E'
                ? 'text-success font-weight-bold'
                : 'text-danger font-weight-bold'
            "
          >
            {{ props.rowData.tipo == "E" ? "Receita" : "Despesa" }}
          </span>
        </span>
        <span slot="meiopagamento" v-if="props.field == 'meiopagamento'">
          <div
            class="font-weight-bold text-muted"
            v-if="props.rowData.meio_pagamento"
          >
            {{ props.rowData.meio_pagamento.descricao }}
          </div>
          <div class="font-weight-bold text-muted" v-else>-</div>
        </span>
        <span slot="plano_contas" v-if="props.field == 'plano_contas'">
          <div
            class="font-weight-bold text-muted"
            v-if="props.rowData.plano_contas"
          >
            {{ props.rowData.plano_contas.descricao }}
          </div>
          <div class="font-weight-bold text-muted" v-else>-</div>
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <router-link
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip="'Editar'"
                  :to="{
                    name: 'financeiroEditar',
                    params: {
                      id: props.rowData.id,
                    },
                  }"
                >
                  <Pencil :size="16" title="Editar" />
                </router-link>
                <button
                  v-if="props.rowData.id_empresa"
                  @click="excluir(props.rowData)"
                  class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip="'Excluir'"
                >
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import FilterFields from "@/components/FilterFields";
import { filtersStore } from "@/components/FilterFields/filtersStore";
import Chart from "@/components/Charts/Charts.vue";
import Transfer from "vue-material-design-icons/Transfer.vue";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";

export default {
  name: "app-financeiro",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
    FilterFields,
    Chart,
    Transfer,
    Money,
  },
  data() {
    return {
      components: {
        flatPickr,
      },
      fields: [
        {
          label: "Data de Movimento",
          field: "data",
          filterable: true,
          slot: true,
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          width: "5%",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Descrição",
          field: "descricao",
          type: "text",
          slot: true,
          sortable: false,
          width: "25%",
        },
        {
          label: "Meio de pagamento",
          field: "meiopagamento",
          slot: true,
          sortable: false,
          width: "15%",
        },
        {
          label: "Plano de contas",
          field: "plano_contas",
          slot: true,
          sortable: false,
          width: "15%",
        },
        {
          label: "Valor",
          field: "valor",
          sortable: true,
          slot: true,
          width: "25%",
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
        },
      ],
      bancos: [],
      transferencia: {},
      filtersStore,
      configMoney: settingMoney,
    };
  },
  watch: {
    "filtersStore.filterOptionSelected": {
      handler(value) {
        switch (value.id) {
          case 1:
            this.getCurrentDayDataFinanceiro("financeiro");
            break;
          case 2:
            this.getYesterdayDataFinanceiro("financeiro");
            break;
          case 3:
            this.getCurrentWeekDataFinanceiro("financeiro");
            break;
          case 4:
            this.getLastWeekDataFiananceiro("financeiro");
            break;
          case 5:
            this.getMonthDataFinanceiro("financeiro");
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    dateRangeFilter(data, filterString) {
      let dateRange = filterString.split("to");
      let startDate = Date.parse(dateRange[0]);
      let endDate = Date.parse(dateRange[1]);
      return (data =
        Date.parse(data) >= startDate && Date.parse(data) <= endDate);
    },
    async salvarTransferencia() {
      if (!this.transferencia.banco) {
        this.$toast.warning("Seleciona a conta para transferência!");
        return;
      }

      if (this.transferencia.valor_tranferencia <= 0) {
        this.$toast.warning("Informe o valor da transferência!");
        return;
      }
      this.addLoadingToButton("salvarTranferencia");
      try {
        await this.$http.post("/financeiro/transferencia", this.transferencia);
        this.$toast.success("Transferência realizada com sucesso!");
        this.transferencia = {};
        this.getCurrentDayDataFinanceiro("financeiro");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("salvarTranferencia");
      this.$root.$emit("bv::toggle::collapse", "sidebar-tranferencia-fields");
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o lançamento: " + data.descricao + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/financeiro/delete", {
                id: data.id,
              });
              this.$refs.financeiro.refresh();
              this.$toast.success("Lançamento excluído!");
            }
          });
      });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    this.bancos = await this.getBancosByEmpresa();
    this.transferencia.data_transferencia = this.thisDate();

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Movimento Caixa",
        action: {
          title: "Novo",
          url: "financeiroNovo",
        },
      },
    ]);
    let inputs = ['input[placeholder="Data"]'];
    inputs.forEach(function (input) {
      flatPickr(input, {
        dateFormat: "Y-m-d",
        allowInput: true,
        locale: Portuguese,
      });
    });
  },
};
</script>

<style>
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: var(--primary-color) !important;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}
</style>
