import { render, staticRenderFns } from "./ItensCompra.vue?vue&type=template&id=8bb62cfa&"
import script from "./ItensCompra.vue?vue&type=script&lang=js&"
export * from "./ItensCompra.vue?vue&type=script&lang=js&"
import style0 from "./ItensCompra.vue?vue&type=style&index=0&id=8bb62cfa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports