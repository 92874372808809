import { required } from "vuelidate/lib/validators";

export default class TransportadorData {
  nome = null;
  cnpj_cpf = null;
  frete = {
    codigo: 9,
    descricao: 'Sem Ocorrrência de Transporte'
  };
  veiculo = 0;
  quantidade = null;
  especie = null;
  marca = null;
  numeracao = null;
  pesoBruto = null;
  pesoLiquido = null;
  enderecos = [];
  constructor(
    nome = null,
    descricao = null,
    cnpj_cpf = null,
    frete = {
      codigo: 9,
      descricao: 'Sem Ocorrrência de Transporte'
    },
    veiculo = 0,
    quantidade = null,
    especie = null,
    marca = null,
    numeracao = null,
    pesoBruto = null,
    pesoLiquido = null,
    enderecos = [],
  ) {
    this.nome = nome;
    this.descricao = descricao;
    this.cnpj_cpf = cnpj_cpf;
    this.frete = frete;
    this.veiculo = veiculo;
    this.quantidade = quantidade;
    this.especie = especie;
    this.marca = marca;
    this.numeracao = numeracao;
    this.pesoBruto = pesoBruto;
    this.pesoLiquido = pesoLiquido;
    this.enderecos = enderecos;
  }
}

export const TransportadorDataRequired = {
  descricao: {
    required,
  },
};
