<template>
  <b-modal
    id="loteModal"
    ref="modalLote"
    title="Selecionar Lote"
    hide-footer
    size="lg"
  >
    <b-row>
      <b-col md="8">
        <p>
          <strong>Produto:</strong> {{ produto?.descricao || "Não informado" }}
        </p>

        <b-row>
          <b-col>
            <b-alert v-if="produto.baixa_automatica_lote" variant="info" show>
              <strong>Atenção:</strong> Este produto está configurado para baixa
              automática de lotes. O sistema selecionará automaticamente o lote
              com vencimento mais próximo.
            </b-alert>
          </b-col>
        </b-row>

        <b-form-group label="Pesquisar Número do Lote">
          <b-form-input
            v-model="filtroLote"
            placeholder="Digite o número do lote..."
            debounce="300"
          />
        </b-form-group>

        <div
          :style="{
            maxHeight: lotesFiltrados.length > 5 ? '300px' : 'auto',
            overflowY: lotesFiltrados.length > 5 ? 'scroll' : 'visible',
          }"
        >
          <b-table
            striped
            hover
            bordered
            :items="loading ? [] : lotesFiltrados"
            :fields="campos"
            select-mode="single"
            selectable
            @row-selected="selecionarLote"
            :busy="loading"
          >
            <template #table-busy>
              <div class="text-center my-3">
                <b-spinner variant="primary" label="Carregando..."></b-spinner>
                <p>Carregando lotes...</p>
              </div>
            </template>

            <template #cell(data_fabricacao)="row">
              {{ formatDate(row.item.data_fabricacao) }}
            </template>

            <template #cell(data_validade)="row">
              {{ formatDate(row.item.data_validade) }}
            </template>

            <template #cell(quantidade)="row">
              <span :class="{ 'text-danger': row.item.quantidade === 0 }">
                {{ numberToReal(row.item.quantidade, "quantidade") }}
                <span v-if="row.item.quantidade === 0"> (Sem estoque)</span>
              </span>
            </template>
          </b-table>
        </div>
      </b-col>

      <!-- Indicação do lote selecionado -->
      <b-col md="4">
        <b-alert v-if="loteSelecionado" show variant="success">
          <p>
            <strong>Lote Selecionado:</strong> {{ loteSelecionado.numero_lote }}
          </p>
          <p>
            <strong>Validade:</strong>
            {{ formatDate(loteSelecionado.data_validade) }}
          </p>
          <p>
            <strong>Disponível:</strong>
            {{ numberToReal(loteSelecionado.quantidade, "quantidade") }}
            unidades
          </p>
        </b-alert>

        <b-alert v-else show variant="warning">
          Nenhum lote selecionado
        </b-alert>

        <b-form-group label="Quantidade">
          <b-form-input
            type="number"
            v-model.number="quantidadeSelecionada"
            :disabled="!loteSelecionado"
            min="1"
            :max="loteSelecionado ? loteSelecionado.quantidade : 1"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-button variant="secondary" @click="fecharModal">Cancelar</b-button>
        <b-button
          variant="primary"
          :disabled="!loteSelecionado || quantidadeSelecionada <= 0"
          @click="confirmarSelecao"
        >
          Confirmar
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { SistemaMixin } from "@/modules/Application";
import { quantityOptions } from "@/modules/Application";

export default {
  name: "LoteModal",
  mixins: [SistemaMixin],
  components: {},
  props: {
    produto: {
      type: Object,
      required: true,
    },
    addProduto: {
      type: Function,
    },
    compra: {
      type: Boolean,
    },
    atualizarLotes: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      filtroLote: "",
      quantityOptions,
      lotes: [],
      loteSelecionado: null,
      quantidadeSelecionada: 1,
      campos: [
        { key: "numero_lote", label: "Lote" },
        { key: "data_fabricacao", label: "Fabricação" },
        { key: "data_validade", label: "Validade" },
        { key: "quantidade", label: "Disponível" },
      ],
    };
  },
  watch: {
    atualizarLotes(value) {
      this.abrirModal();
    },
  },
  computed: {
    lotesFiltrados() {
      let lotes = [...this.lotes];

      lotes = lotes
        .filter((lote) => lote.quantidade > 0)
        .sort((a, b) => new Date(a.data_validade) - new Date(b.data_validade));

      if (this.filtroLote) {
        return lotes.filter((lote) =>
          lote.numero_lote.toLowerCase().includes(this.filtroLote.toLowerCase())
        );
      }

      return lotes;
    },
  },
  methods: {
    selecionarLote(selecionado) {
      if (selecionado.length === 0) {
        this.loteSelecionado = null;
        this.quantidadeSelecionada = 0;
        return;
      }

      const lote = selecionado[0];

      if (lote.quantidade <= 0) {
        this.$toast.warning(
          "Este lote não pode ser selecionado porque está sem estoque!"
        );
        return;
      }

      this.loteSelecionado = lote;
      this.quantidadeSelecionada = 1;
    },
    confirmarSelecao() {
      if (!this.compra) {
        if (this.loteSelecionado.quantidade < this.quantidadeSelecionada) {
          this.$toast.warning(
            "Quantidade informada maior que a do lote selecionado!"
          );
          return;
        }
      }

      if (this.loteSelecionado && this.quantidadeSelecionada > 0) {
        this.loteSelecionado.quantidade = this.formatNumberToFloat(
          this.quantidadeSelecionada
        );
        this.produto = {
          ...this.produto,
          lote_baixa: this.loteSelecionado,
          preco: this.formatNumberToFloat(this.produto.preco),
          quantidade: this.formatNumberToFloat(this.quantidadeSelecionada),
        };

        this.$emit("onAddProduto", {
          newPreco: this.produto.preco,
          newQuantidade: this.quantidadeSelecionada,
          newTotal:
            this.formatNumberToFloat(this.produto.preco) *
            this.quantidadeSelecionada,
          newProduto: this.produto,
        });

        this.produto.descricao = `${this.produto.descricao} - Nº do Lote: ${
          this.loteSelecionado.numero_lote
        } / Dt Validade: ${this.formatDate(
          this.loteSelecionado.data_validade
        )}`;
        this.addProduto(this.produto);

        this.fecharModal();
      }
    },
    async abrirModal() {
      this.$nextTick(() => {
        if (this.$refs.modalLote) {
          this.$refs.modalLote.show();
        }
      });
      this.loading = true;
      this.loteSelecionado = null;
      this.quantidadeSelecionada = 0;

      try {
        const response = await this.$http.post("/produtos/get-lote", {
          id_produto: this.produto.id,
          compra: this.compra,
        });

        this.lotes = response.data || [];
        this.loading = false;

        if (
          this.produto.baixa_automatica_lote &&
          this.lotesFiltrados.length > 0
        ) {
          this.loteSelecionado = this.lotesFiltrados[0];
          this.quantidadeSelecionada = 1;
          this.$toast.success(
            `Lote ${this.loteSelecionado.numero_lote} foi sugerido automaticamente!`
          );
        }
      } catch (error) {
        this.$toast.error("Erro ao carregar lotes do servidor.");
        this.loading = false;
      }
    },
    fecharModal() {
      this.loteSelecionado = null;
      this.quantidadeSelecionada = 0;
      this.filtroLote = "";
      this.$refs.modalLote.hide();
    },
  },
};
</script>
