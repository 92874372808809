<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Configurações
        </span>
      </h3>
    </div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col>
                <b-form-group
                  id="numero_nfse"
                  label-for="numero_nfse"
                  label="Número NFSe"
                >
                  <b-form-input v-model="form.numero_nfse" autofocus />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="serie" label-for="serie" label="Série">
                  <b-form-input v-model="form.serie" autofocus />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="regimeTributarioEspecial"
                  label-for="regimeTributarioEspecial"
                  label="Regime tributário"
                >
                  <b-form-select
                    v-model="form.regimeTributarioEspecial"
                    placeholder=""
                  >
                    <b-form-select-option
                      v-for="regime in regimesTributarios"
                      :key="regime.id + 'regime'"
                      :value="regime.id"
                    >
                      {{ regime.description }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group
                  id="usuario_prefeitura"
                  label-for="usuario_prefeitura"
                  label="Usurário prefeitura"
                >
                  <b-form-input v-model="form.usuario_prefeitura" autofocus />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="senha_prefeitura"
                  label-for="senha_prefeitura"
                  label="Senha prefeitura"
                >
                  <b-form-input
                    type="password"
                    v-model="form.senha_prefeitura"
                    autofocus
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row> </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button
              type="submit"
              ref="submit_configuracao"
              @click="onSubmit"
              class="btn btn-primary font-weight-bold"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSelect from "vue-select";
import _ from "lodash";
import { mapActions } from "vuex";

import { SistemaMixin, EmitenteMixin } from "@/modules/Application";

export default {
  name: "app-servico-configuracoes",
  components: {},
  data() {
    return {
      form: {},
      tabIndex: 0,
      regimesTributarios: [],
      render: 0,
      checkNewCeritificate: true,
    };
  },

  mixins: [SistemaMixin, EmitenteMixin],
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    setImage(event) {
      this.form.certificado = event.target.files[0];
      this.checkNewCeritificate = false;
    },

    async getDataInit() {
      this.setLoading(true);
      const { data } = await this.$http.post(
        "/configuracoesnfse/regime-tributario-especial"
      );
      try {
        const { data: responseConfig } = await this.$http.post(
          "/configuracoesnfse/find"
        );
        if (responseConfig) {
          this.form.numero_nfse = responseConfig.numero_nfse;
          this.form.serie = responseConfig.serie;
          this.form.usuario_prefeitura =
            responseConfig.nfse.config.prefeitura.login;
          this.form.senha_prefeitura =
            responseConfig.nfse.config.prefeitura.senha;
          this.form.regimeTributarioEspecial =
            responseConfig.regimeTributarioEspecial;
          this.form.id_configuracao_nfse = responseConfig.id_configuracao_nfse;
        }
      } catch (error) {
        console.log(error);
      }
      this.regimesTributarios = [...data];

      this.setLoading(false);
    },
    async onSubmit() {
      const configuracao = new FormData();

      this.addLoadingToButton("submit_configuracao");

      if (!this.form.regimeTributarioEspecial) {
        this.$toast.warning("Selecione o regime tributário!");
      }

      if (this.form.numero_nfse !== null) {
        configuracao.append("numero_nfse", this.form.numero_nfse);
      }
      if (this.form.serie !== null) {
        configuracao.append("serie", this.form.serie);
      }
      if (this.form.regimeTributarioEspecial !== null) {
        configuracao.append(
          "regimeTributarioEspecial",
          this.form.regimeTributarioEspecial
        );
      }
      if (this.form.usuario_prefeitura !== null) {
        configuracao.append("usuario_prefeitura", this.form.usuario_prefeitura);
      }
      if (this.form.senha_prefeitura !== null) {
        configuracao.append("senha_prefeitura", this.form.senha_prefeitura);
      }
      if (this.form.id_configuracao_nfse !== null) {
        configuracao.append(
          "id_configuracao_nfse",
          this.form.id_configuracao_nfse
        );
      }

      try {
        if (this.form.id_configuracao_nfse) {
          await this.$http.post("/configuracoesnfse/update", configuracao);
          this.$toast.success("Configuração atualizada com sucesso!");
          this.$emit("configuracaoNFSe");
        } else {
          await this.$http.post("/configuracoesnfse/create", configuracao);
          this.$toast.success("Configuração salva com sucesso!");
          this.$emit("configuracaoNFSe");
        }
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("submit_configuracao");
    },
  },
  created() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
};
</script>
